import { ImageModelNames } from '@/utils/config';

export type TODO = any;

export enum JobStatus {
  pending = 'pending',
  queued = 'queued',
  running = 'running',
  processing = 'processing',
  uploading = 'uploading',
  completed = 'completed',
  failed = 'failed',
  cancelled = 'cancelled',
  skipped = 'skipped',
  retrying = 'retrying',
  describing = 'describing',
  waiting = 'waiting',
  generating = 'generating',
  regenerating = 'regenerating',
}

export type Job = {
  path: string;
  type: string;
  status: JobStatus;
  createdAt: Date;
  updatedAt: Date;
};

export enum JobType {
  luma = 'luma',
  lumaExtend = 'lumaExtend',
  metaMusicgen = 'metaMusicgen',
  runwaymlGen3Turbo = 'runwaymlGen3Turbo',
  runwaymlGen3 = 'runwaymlGen3',
  describe = 'describe',
  midJourneyLegacy = 'midJourneyLegacy',
  midJourney = 'midJourney',
  midJourneyVariation = 'midJourneyVariation',
  midJourneyZoomOut = 'midJourneyZoomOut',
  sd = 'sd',
  sdUpscale = 'sdUpscale',
  flux = 'flux',
  fluxUltra = 'fluxUltra',
  ideogram = 'ideogram',
  recraft = 'recraft',
  dallE3 = 'dallE3',
  promptGeneration = 'promptGeneration',
  sdMotion = 'sdMotion',
  replicateMotion = 'replicateMotion',
  suno = 'suno',
  sd3 = 'sd3',
  document = 'document',
  documentSuggestion = 'documentSuggestion',
}

export const MidJourneyJobTypes = [
  JobType.midJourney,
  JobType.midJourneyVariation,
  JobType.midJourneyZoomOut,
];

export const RunwaymlJobTypes = [
  JobType.runwaymlGen3Turbo,
  JobType.runwaymlGen3,
];

export const JobTypeRate = {
  [JobType.luma]: 1,
  [JobType.lumaExtend]: 1,
  [JobType.metaMusicgen]: 1,
  [JobType.runwaymlGen3Turbo]: 1,
  [JobType.runwaymlGen3]: 1,
  [JobType.describe]: 1,
  [JobType.midJourneyLegacy]: 1,
  [JobType.midJourney]: 1,
  [JobType.midJourneyVariation]: 1,
  [JobType.midJourneyZoomOut]: 1,
  [JobType.sd]: 1,
  [JobType.sdUpscale]: 1,
  [JobType.flux]: 1,
  [JobType.fluxUltra]: 1,
  [JobType.ideogram]: 1,
  [JobType.recraft]: 1,
  [JobType.dallE3]: 1,
  [JobType.promptGeneration]: 1,
  // deprecated
  [JobType.sdMotion]: 1,
  [JobType.replicateMotion]: 1,
  [JobType.suno]: 1,
  [JobType.sd3]: 1,
} as const;

const LLMJobTypeRate = {
  openai: 1,
  'openai-gpt-4.5-preview': 1,
  'openai-mini': 1,
  'openai-o1-mini': 1,
  'openai-o1-preview': 1,
  'openai-o1': 1,
  'openai-o3-mini': 1,
  anthropic: 1,
  'anthropic-opus': 1,
  gemini: 1,
  meta: 1,
  perplexity: 1,
  mistral: 1,
  'deepseek-v3': 1,
  'deepseek-r1': 1,
};

export const JobTypeMaxConcurrent = {
  [JobType.midJourney]: 1,
  [JobType.midJourneyVariation]: 1,
  [JobType.midJourneyZoomOut]: 1,
  [JobType.runwaymlGen3Turbo]: 1,
  [JobType.runwaymlGen3]: 1,
} as const;

export const JobTypeLabelMap = {
  [JobType.luma]: 'Luma',
  [JobType.lumaExtend]: 'Luma Extend',
  [JobType.metaMusicgen]: 'Music Gen',
  [JobType.runwaymlGen3Turbo]: 'Runway Gen 3 Turbo',
  [JobType.runwaymlGen3]: 'Runway Gen 3',
  [JobType.describe]: 'Describe',
  [JobType.midJourneyLegacy]: 'Mid Journey (Legacy)',
  [JobType.midJourney]: 'Mid Journey',
  [JobType.midJourneyVariation]: 'Variation',
  [JobType.midJourneyZoomOut]: 'Zoom Out',
  [JobType.sd]: 'Stable Diffusion',
  [JobType.sdUpscale]: 'Creative Upscale',
  [JobType.flux]: 'Flux',
  [JobType.fluxUltra]: 'Flux Ultra',
  [JobType.ideogram]: 'Ideogram',
  [JobType.recraft]: 'Recraft',
  [JobType.dallE3]: 'Dall-E 3',
  [JobType.promptGeneration]: 'Prompt Magic',
  // deprecated
  [JobType.sdMotion]: 'Motion',
  [JobType.replicateMotion]: 'Motion',
  [JobType.suno]: 'Suno',
  [JobType.sd3]: 'Stable Diffusion',
} as const;

// Temp for image models mapping
export const JobTypeMap = {
  [ImageModelNames.midJourney]: JobType.midJourney,
  midJourneyVariation: JobType.midJourneyVariation,
  [ImageModelNames.sd]: JobType.sd,
  [ImageModelNames.flux]: JobType.flux,
  [ImageModelNames.fluxUltra]: JobType.fluxUltra,
  [ImageModelNames.ideogram]: JobType.ideogram,
  [ImageModelNames.recraft]: JobType.recraft,
  [ImageModelNames.dallE3]: JobType.dallE3,
} as const;
