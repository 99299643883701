import { useEffect, useMemo } from 'react';

import useUser from './useUser';
import useUsersStore from '@/store/users';
import useGlobalStore from '@/store/global';

const useWs = (props: { wsId: string; uid?: string; fetchUsers?: boolean }) => {
  const { wsId, uid, fetchUsers = false } = props;
  const { isSuperAdmin } = useGlobalStore();
  const { getUser } = useUsersStore();

  // workspace
  const ws = useUser({ uid: wsId });
  // user
  const user = useUser({ uid });

  // primaryOwner
  // owner
  // editor
  // collaborator
  // __
  // viewer

  const wsPrivilege = useMemo(() => {
    const privilege = {
      role: 'unknown',
      // all roles allow create if ws has subscription
      canView: false, // reader / collaborator - can collaborate on workspace contents
      canEdit: false, // editor - can manage/edit workspace contents
      canManage: false, // owner - can manage entire workspace including org / team / billing / contents
    };

    if (wsId === uid || isSuperAdmin) {
      privilege.role = 'primaryOwner';
      privilege.canView = true;
      privilege.canEdit = true;
      privilege.canManage = true;
    }

    if (!ws || !user) return privilege;

    const role =
      ws.members.find(m => m.email === user.email)?.role || 'unknown';

    if (role === 'reader') {
      privilege.role = 'reader';
      privilege.canView = true;
      privilege.canEdit = false;
      privilege.canManage = false;
    }

    if (role === 'editor') {
      privilege.role = 'editor';
      privilege.canView = true;
      privilege.canEdit = true;
      privilege.canManage = false;
    }

    if (role === 'owner') {
      privilege.role = 'owner';
      privilege.canView = true;
      privilege.canEdit = true;
      privilege.canManage = true;
    }

    return privilege;
  }, [wsId, uid, isSuperAdmin, ws, user]);

  const { user: userAsWs, ...wsProps } = ws;

  useEffect(() => {
    if (!fetchUsers) return;
    if (!ws) return;
    const { members } = ws;
    for (const member of members) {
      getUser(member.email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    ws: userAsWs,
    ...wsProps,
    wsPrivilege,
    canView: wsPrivilege.canView,
    canEdit: wsPrivilege.canEdit,
    canManage: wsPrivilege.canManage,
    hasWsMembers: ws.members.filter(m => m.role !== 'primaryOwner').length > 0,
  };
};

export default useWs;
