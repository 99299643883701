import _ from 'lodash';
import { useEffect } from 'react';
import { useEffectOnce } from 'react-use';

import useBrandsStore from 'store/brands';

const useBrands = (props: { wsId: string; uid: string }) => {
  const { wsId, uid } = props;
  const { brands, getBrands } = useBrandsStore();

  useEffectOnce(() => {
    getBrands(wsId, uid, true);
  });

  return {
    brands: _.sortBy(
      brands.filter(brand => !brand.isArchive),
      ['name']
    ),
  };
};

export default useBrands;
