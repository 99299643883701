import {
  addDoc,
  collection,
  doc,
  DocumentData,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  QueryDocumentSnapshot,
  serverTimestamp,
  startAfter,
  updateDoc,
} from 'firebase/firestore';
import _ from 'lodash';
import create from 'zustand';

import { toast } from 'components/ui/use-toast';
import { db, updateRecents } from 'utils/api/firebase';

import { Collection } from './collections.types';
import { TODO } from './global.types';

export interface ColletionsStoreType {
  initialLoading: boolean;
  setInitialLoading: (initialLoading: boolean) => void;

  collections: Collection[];
  syncCollection: (collection: Collection) => void;

  lastVisible: QueryDocumentSnapshot<DocumentData, DocumentData>;
  setLastVisible: (
    lastVisible: QueryDocumentSnapshot<DocumentData, DocumentData>
  ) => void;

  hasMore: boolean;
  setHasMore: (hasMore: boolean) => void;

  limitCount: number;

  getCollections: (wsId: string, uid: string, all?: boolean) => void;

  getCollection: (wsId: string, collectionId: string) => TODO;

  createCollection: (wsId: string, uid: string, data: TODO) => void;

  updateCollection: (docRef: TODO, data: TODO, uid?: string) => void;
}

const useCollectionsStore = create<ColletionsStoreType>((set, get) => ({
  initialLoading: true,
  setInitialLoading: (initialLoading: boolean) => {
    set(() => ({ initialLoading }));
  },

  collections: [],
  syncCollection: collection => {
    set(({ collections }) => {
      const collectionIndex = collections.findIndex(
        c => c.id === collection.id
      );
      if (collectionIndex === -1)
        return { collections: [...collections, collection] };
      const updatedcollections = collections.map((t, index) =>
        index === collectionIndex ? { ...t, ...collection } : t
      );

      return {
        collections: _.sortBy(updatedcollections, ['name']),
      };
    });
  },

  lastVisible: null,
  setLastVisible: lastVisible => {
    set(() => ({ lastVisible }));
  },

  hasMore: true,
  setHasMore: hasMore => {
    set(() => ({ hasMore }));
  },

  limitCount: 25,

  getCollections: async (wsId, uid, all = false) => {
    if (!wsId || !uid) return;
    const {
      limitCount,
      lastVisible,
      syncCollection,
      hasMore,
      setHasMore,
      setLastVisible,
      getCollections,
      setInitialLoading,
    } = get();

    if (!hasMore) return;

    // init q
    let q = query(collection(db, 'users', wsId, 'collections'));
    // add order desc and limit
    q = query(q, orderBy('createdAt', 'desc'), limit(limitCount));
    // has no more starred
    if (lastVisible) {
      q = query(q, startAfter(lastVisible));
    }
    const documentSnapshots = await getDocs(q);
    setInitialLoading(false);
    documentSnapshots.forEach(doc => {
      const { createdBy, isShared } = doc.data();
      if (
        (!createdBy && wsId === uid) ||
        (createdBy && createdBy === uid) ||
        isShared
      )
        syncCollection({ id: doc.id, ref: doc.ref, ...doc.data() });
    });
    if (documentSnapshots.empty) setHasMore(false);
    if (documentSnapshots.size) {
      setLastVisible(documentSnapshots.docs[documentSnapshots.size - 1]);
      if (all) getCollections(wsId, uid, all);
    }
  },

  getCollection: async (wsId, collectionId) => {
    if (!wsId || !collectionId) return;
    const { collections, syncCollection } = get();
    const syncedCollection = collections.find(c => c.id === collectionId);
    if (syncedCollection) return syncedCollection;
    const docRef = doc(db, 'users', wsId, 'collections', collectionId);
    const docSnap = await getDoc(docRef);
    const collection = docSnap.exists()
      ? { id: docSnap.id, ref: docSnap.ref, ...docSnap.data() }
      : null;
    if (collection) syncCollection(collection);
    return collection;
  },

  createCollection: async (wsId, uid, data) => {
    try {
      const collectionsRef = collection(db, 'users', wsId, 'collections');
      const collectionDocRef = await addDoc(collectionsRef, {
        ...data,
        createdBy: uid,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      });
      updateRecents({ uid, path: collectionDocRef.path });
    } catch (error) {
      console.log('useCollectionsStore -- createCollection -- error:', error);
      toast({
        title: 'Something went wrong. Please try again.',
      });
    }
  },

  updateCollection: async (docRef, data) => {
    try {
      await updateDoc(docRef, {
        ...data,
        updatedAt: serverTimestamp(),
      });
    } catch (error) {
      console.log('useCollectionsStore -- updateCollection -- error:', error);
      toast({
        title: 'Something went wrong. Please try again.',
      });
    }
  },
}));

export default useCollectionsStore;
