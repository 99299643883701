import { ExternalToast } from 'sonner';
import { llmModelsNames, llmModels } from './api/common';

export const ISR_TIMEOUT = process.env.ENV === 'production' ? 300 : 10;

export const delay = (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

export const ALLOWED_ORIGINS = [
  'http://localhost:3000',
  'https://preview.euryka.ai',
  'https://app.euryka.ai',
  'https://app-aags7tzujri.canva-apps.com',
  'https://app-aagnduic8p0.canva-apps.com',
  'https://app-aaguxzd82ec.canva-apps.com',
  'https://app-aagu2d-yz1e.canva-apps.com',
  'https://app-aagax0algr0.canva-apps.com',
  'https://app-aagcvrtpyug.canva-apps.com',
  'https://www.canva.com',
];

export const uidToHexColor = (uid: string) => {
  if (!uid) return '00000000';
  // Create a hash from the UID
  let hash = 0;
  for (let i = 0; i < uid.length; i++) {
    hash = uid.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Convert the hash to a hex color code
  let color = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += ('00' + value.toString(16)).slice(-2);
  }

  return color;
};

export const TOAST_DEFAULTS: Partial<ExternalToast> = {
  position: 'top-right',
  closeButton: true,
  style: { top: 40 },
  duration: 3000,
  classNames: {
    closeButton: 'left-[unset] -right-[1.125rem] -top-1',
  },
};

export const THREAD_MODEL_KEY = 'threadModelV7';

export const DOCS_VIEW_KEY = 'docViewV2';
export const DOCS_VIEW = {
  NARROW: 'narrow',
  WIDE: 'wide',
};

export const SPARK_MODEL_OPTIONS = [
  {
    name: llmModelsNames[llmModels.openai],
    value: llmModels.openai,
  },
  {
    name: llmModelsNames[llmModels.anthropic],
    value: llmModels.anthropic,
  },
  {
    name: llmModelsNames[llmModels.meta],
    value: llmModels.meta,
  },
  {
    name: llmModelsNames[llmModels.gemini],
    value: llmModels.gemini,
  },
  {
    name: llmModelsNames[llmModels['gemini-2.5-pro-exp-03-25']],
    value: llmModels['gemini-2.5-pro-exp-03-25'],
    lucideIcon: 'Sparkle',
  },
  {
    name: llmModelsNames[llmModels.mistral],
    value: llmModels.mistral,
  },
  {
    name: llmModelsNames[llmModels['deepseek-v3']],
    value: llmModels['deepseek-v3'],
    icon: 'deepseek',
  },
];

export const modelVersions = [
  {
    key: 'stereo-melody-large',
    label: 'Echoes of Harmony',
    description:
      'Captures the essence of blending stereo sound and melody, suggesting a model that creates rich, harmonious echoes in music.',
  },
  {
    key: 'stereo-large',
    label: 'Sonic Panorama',
    description:
      "Emphasizes the model's ability to generate broad, immersive stereo soundscapes, akin to a rhapsodic exploration of audio panoramas.",
  },
  {
    key: 'melody-large',
    label: 'Lyrical Weaver',
    description:
      "Reflects the model's focus on melody, implying it weaves intricate, lyrical tapestries from musical notes.",
  },
  {
    key: 'large',
    label: 'Symphony Synthesist',
    description:
      "Conveys the model's extensive capacity to synthesize diverse musical elements into a cohesive symphony.",
  },
];

export const OrientationNames = {
  square: 'square',
  rectangle: 'rectangle',
  'rectangle-wide': 'rectangle-wide',
  landscape: 'landscape',
  'landscape-wide': 'landscape-wide',
  'rectangle-tall': 'rectangle-tall',
  'rectangle-wide-tall': 'rectangle-wide-tall',
  portrait: 'portrait',
  'landscape-wide-tall': 'landscape-wide-tall',
} as const;

export const orientations = {
  [OrientationNames.square]: '1:1',
  [OrientationNames.rectangle]: '4:3',
  [OrientationNames['rectangle-wide']]: '3:2',
  [OrientationNames.landscape]: '16:9',
  [OrientationNames['landscape-wide']]: '21:9',
  [OrientationNames['rectangle-tall']]: '3:4',
  [OrientationNames['rectangle-wide-tall']]: '2:3',
  [OrientationNames.portrait]: '9:16',
  [OrientationNames['landscape-wide-tall']]: '9:21',
} as const;

export const aspectRatiosIdeogram = {
  [OrientationNames.square]: 'ASPECT_1_1',
  [OrientationNames.rectangle]: 'ASPECT_4_3',
  [OrientationNames['rectangle-wide']]: 'ASPECT_3_2',
  [OrientationNames.landscape]: 'ASPECT_16_9',
  [OrientationNames['rectangle-tall']]: 'ASPECT_3_4',
  [OrientationNames['rectangle-wide-tall']]: 'ASPECT_2_3',
  [OrientationNames.portrait]: 'ASPECT_9_16',
} as const;

export const orientationsImage = [
  {
    key: OrientationNames.square,
    label: orientations[OrientationNames.square],
  },
  {
    key: OrientationNames.rectangle,
    label: orientations[OrientationNames.rectangle],
  },
  {
    key: OrientationNames['rectangle-wide'],
    label: orientations[OrientationNames['rectangle-wide']],
  },
  {
    key: OrientationNames.landscape,
    label: orientations[OrientationNames.landscape],
  },
  {
    key: OrientationNames['rectangle-tall'],
    label: orientations[OrientationNames['rectangle-tall']],
  },
  {
    key: OrientationNames['rectangle-wide-tall'],
    label: orientations[OrientationNames['rectangle-wide-tall']],
  },
  {
    key: OrientationNames.portrait,
    label: orientations[OrientationNames.portrait],
  },
];

export const aspectSizesRecraft = {
  [OrientationNames.square]: '1024x1024',
  [OrientationNames.rectangle]: '1365x1024',
  [OrientationNames['rectangle-wide']]: '1536x1024',
  [OrientationNames.landscape]: '1820x1024',
  [OrientationNames['landscape-wide']]: '2048x1024',
  [OrientationNames['rectangle-tall']]: '1024x1365',
  [OrientationNames['rectangle-wide-tall']]: '1024x1536',
  [OrientationNames.portrait]: '1024x1820',
  [OrientationNames['landscape-wide-tall']]: '1707x1024',
} as const;

export const aspectSizesSD = {
  [OrientationNames.square]: '1024x1024',
  [OrientationNames.rectangle]: '1024x768',
  [OrientationNames['rectangle-wide']]: '1120x736',
  [OrientationNames.landscape]: '1184x672',
  [OrientationNames['rectangle-tall']]: '768x1024',
  [OrientationNames['rectangle-wide-tall']]: '736x1120',
  [OrientationNames.portrait]: '672x1184',
} as const;

export const ImageModelNames = {
  midJourney: 'midJourney',
  sd: 'sd',
  flux: 'flux',
  fluxUltra: 'fluxUltra',
  dallE3: 'dallE3',
  ideogram: 'ideogram',
  recraft: 'recraft',
} as const;

export const VideoModelNames = {
  ray16: 'ray-1-6',
  ray2: 'ray-2',
  gen3turbo: 'gen3turbo',
  gen3: 'gen3',
} as const;

export const videoModelsOptions = [
  {
    key: VideoModelNames.ray16,
    label: 'Luma',
  },
  {
    key: VideoModelNames.ray2,
    label: 'Luma Ray 2',
  },
  {
    key: VideoModelNames.gen3turbo,
    label: 'Runway Gen3 Turbo',
  },
  {
    key: VideoModelNames.gen3,
    label: 'Runway Gen3',
  },
];

export const orienationsLuma = [
  {
    key: OrientationNames.square,
    label: orientations[OrientationNames.square],
  },
  {
    key: OrientationNames.rectangle,
    label: orientations[OrientationNames.rectangle],
  },
  {
    key: OrientationNames.landscape,
    label: orientations[OrientationNames.landscape],
  },
  {
    key: OrientationNames['landscape-wide'],
    label: orientations[OrientationNames['landscape-wide']],
  },
  {
    key: OrientationNames['rectangle-tall'],
    label: orientations[OrientationNames['rectangle-tall']],
  },
  {
    key: OrientationNames.portrait,
    label: orientations[OrientationNames.portrait],
  },
  {
    key: OrientationNames['landscape-wide-tall'],
    label: orientations[OrientationNames['landscape-wide-tall']],
  },
];

export const orienationsRunwaymlGen3Turbo = [
  {
    key: OrientationNames.landscape,
    label: orientations[OrientationNames.landscape],
  },
  {
    key: OrientationNames.portrait,
    label: orientations[OrientationNames.portrait],
  },
];

export const orienationsRunwaymlGen3 = [
  {
    key: OrientationNames.landscape,
    label: orientations[OrientationNames.landscape],
  },
];

export const dallE3Styles = [
  {
    key: 'vivid',
    label: 'Vivid',
  },
  {
    key: 'natural',
    label: 'Natural',
  },
];

export const orientationsDallE3 = [
  {
    key: OrientationNames.square,
    label: orientations[OrientationNames.square],
  },
  {
    key: OrientationNames.landscape,
    label: orientations[OrientationNames.landscape],
  },
  {
    key: OrientationNames.portrait,
    label: orientations[OrientationNames.portrait],
  },
];

export const aspectSizesDallE = {
  [OrientationNames.square]: '1024x1024',
  [OrientationNames.landscape]: '1792x1024',
  [OrientationNames.portrait]: '1024x1792',
};

export const ideogramStyles = [
  {
    key: 'AUTO',
    label: 'Auto',
  },
  {
    key: 'GENERAL',
    label: 'General',
  },
  {
    key: 'REALISTIC',
    label: 'Realistic',
  },
  {
    key: 'DESIGN',
    label: 'Design',
  },
  {
    key: 'RENDER_3D',
    label: 'Render 3D',
  },
  {
    key: 'ANIME',
    label: 'Anime',
  },
];

export const sdStyles = [
  {
    label: '3D Render',
    key: 'debdf72a-91a4-467b-bf61-cc02bdeb69c6',
  },
  {
    label: 'Bokeh',
    key: '9fdc5e8c-4d13-49b4-9ce6-5a74cbb19177',
  },
  {
    label: 'Cinematic',
    key: 'a5632c7c-ddbb-4e2f-ba34-8456ab3ac436',
  },
  {
    label: 'Cinematic Concept',
    key: '33abbb99-03b9-4dd7-9761-ee98650b2c88',
  },
  {
    label: 'Creative',
    key: '6fedbf1f-4a17-45ec-84fb-92fe524a29ef',
  },
  {
    label: 'Dynamic',
    key: '111dc692-d470-4eec-b791-3475abac4c46',
  },
  {
    label: 'Fashion',
    key: '594c4a08-a522-4e0e-b7ff-e4dac4b6b622',
  },
  {
    label: 'Graphic Design Pop Art',
    key: '2e74ec31-f3a4-4825-b08b-2894f6d13941',
  },
  {
    label: 'Graphic Design Vector',
    key: '1fbb6a68-9319-44d2-8d56-2957ca0ece6a',
  },
  {
    label: 'HDR',
    key: '97c20e5c-1af6-4d42-b227-54d03d8f0727',
  },
  {
    label: 'Illustration',
    key: '645e4195-f63d-4715-a3f2-3fb1e6eb8c70',
  },
  {
    label: 'Macro',
    key: '30c1d34f-e3a9-479a-b56f-c018bbc9c02a',
  },
  {
    label: 'Minimalist',
    key: 'cadc8cd6-7838-4c99-b645-df76be8ba8d8',
  },
  {
    label: 'Moody',
    key: '621e1c9a-6319-4bee-a12d-ae40659162fa',
  },
  {
    label: 'None',
    key: '556c1ee5-ec38-42e8-955a-1e82dad0ffa1',
  },
  {
    label: 'Portrait',
    key: '8e2bc543-6ee2-45f9-bcd9-594b6ce84dcd',
  },
  {
    label: 'Pro B&W photography',
    key: '22a9a7d2-2166-4d86-80ff-22e2643adbcf',
  },
  {
    label: 'Pro color photography',
    key: '7c3f932b-a572-47cb-9b9b-f20211e63b5b',
  },
  {
    label: 'Pro film photography',
    key: '581ba6d6-5aac-4492-bebe-54c424a0d46e',
  },
  {
    label: 'Portrait Fashion',
    key: '0d34f8e1-46d4-428f-8ddd-4b11811fa7c9',
  },
  {
    label: 'Ray Traced',
    key: 'b504f83c-3326-4947-82e1-7fe9e839ec0f',
  },
  {
    label: 'Sketch (B&W)',
    key: 'be8c6b58-739c-4d44-b9c1-b032ed308b61',
  },
  {
    label: 'Sketch (Color)',
    key: '093accc3-7633-4ffd-82da-d34000dfc0d6',
  },
  {
    label: 'Stock Photo',
    key: '5bdc3f2a-1be6-4d1c-8e77-992a30824a2c',
  },
  {
    label: 'Vibrant',
    key: 'dee282d3-891f-4f73-ba02-7f8131e5541b',
  },
];

export const recraftStyles = [
  {
    key: 'any',
    label: 'Auto',
  },
  {
    key: 'realistic_image',
    label: 'Realistic',
  },
  {
    key: 'realistic_image/b_and_w',
    label: 'B&W',
  },
  {
    key: 'realistic_image/enterprise',
    label: 'Enterprise',
  },
  {
    key: 'realistic_image/hard_flash',
    label: 'Hard Flash',
  },
  {
    key: 'realistic_image/hdr',
    label: 'HDR',
  },
  {
    key: 'realistic_image/motion_blur',
    label: 'Motion Blur',
  },
  {
    key: 'realistic_image/natural_light',
    label: 'Natural Light',
  },
  {
    key: 'realistic_image/studio_portrait',
    label: 'Studio Portrait',
  },
  {
    key: 'digital_illustration',
    label: 'Illustration',
  },
  {
    key: 'digital_illustration/pixel_art',
    label: 'Pixel Art',
  },
  {
    key: 'digital_illustration/hand_drawn',
    label: 'Hand Drawn',
  },
  {
    key: 'digital_illustration/grain',
    label: 'Grain',
  },
  {
    key: 'digital_illustration/infantile_sketch',
    label: 'Infantile Sketch',
  },
  {
    key: 'digital_illustration/2d_art_poster',
    label: '2D Art Poster',
  },
  {
    key: 'digital_illustration/handmade_3d',
    label: 'Handmade 3D',
  },
  {
    key: 'digital_illustration/hand_drawn_outline',
    label: 'Hand Drawn Outline',
  },
  {
    key: 'digital_illustration/engraving_color',
    label: 'Engraving Color',
  },
  {
    key: 'digital_illustration/2d_art_poster_2',
    label: '2D Art Poster',
  },
];

export const IMAGINATIONS_LIST_VIEW_KEY = 'imaginationsListViewV3';
export const IMAGINATIONS_LIST_VIEW = {
  ITERATIONS: 'iterations',
  IMAGINATIONS: 'imaginations',
};

export const IMAGINATIONS_VIEW_KEY = 'imaginationsViewV3';
export const IMAGINATIONS_VIEW = {
  GRID: 'grid',
  TIMELINE: 'timeline',
};

export const IMAGINATION_ITERATIONS_VIEW_KEY = 'imaginationIterationsView';
export const IMAGINATION_ITERATIONS_VIEW = {
  ITERATIONS: 'iterations',
  GENERATIONS: 'generations',
};
