import { zodResolver } from '@hookform/resolvers/zod';
import { Loader2 } from 'lucide-react';
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as z from 'zod';

import { Button } from 'components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog';
import { Form } from 'components/ui/form';
import useImaginations from 'hooks/useImaginations';
import { useGlobalStore } from 'store';
import { FormImage, FormInput, FormSwitch } from '../ui/form.utils';

export interface LayoutDescribeDialogProps {}

const defaultProps: Partial<LayoutDescribeDialogProps> = {};

const formSchema = z.object({
  title: z.string().nonempty({ message: 'Title is required' }),
  image: z.instanceof(File, {
    message: 'Image is required',
  }),
  shdDescribe: z.boolean(),
});

const formDefaultValues = {
  title: '',
  image: null,
  shdDescribe: true,
};

const LayoutDescribeDialog: React.FC<LayoutDescribeDialogProps> = () => {
  const { isDescribeOpen, setDescribeOpen } = useGlobalStore();
  const { onDescribe } = useImaginations();
  const [isLoading, setLoading] = useState(false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: formDefaultValues,
  });

  const formReset = useCallback(() => {
    form.reset(formDefaultValues);
  }, [form]);

  const onSubmit = useCallback(
    async (values: z.infer<typeof formSchema>) => {
      setLoading(true);
      try {
        await onDescribe(values);
        setDescribeOpen(false);
      } finally {
        setLoading(false);
      }
    },
    [onDescribe, setDescribeOpen]
  );

  return (
    <Dialog
      open={isDescribeOpen}
      onOpenChange={value => {
        setDescribeOpen(value);
        if (!value) formReset();
      }}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add a file</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <FormInput
              {...{
                form,
                name: 'title',
                label: 'Title',
                placeholder: 'Add a title...',
              }}
            />

            <FormSwitch
              {...{
                form,
                name: 'shdDescribe',
                label: 'Describe this image.',
              }}
            />

            <FormImage
              {...{
                form,
                name: 'image',
                label: 'Image file',
                size: 'small',
              }}
            />

            <Button type="submit" disabled={isLoading} className={'float-end'}>
              {isLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
              Continue
            </Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

LayoutDescribeDialog.defaultProps = defaultProps;

export default LayoutDescribeDialog;
