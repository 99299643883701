import * as Sentry from '@sentry/react';
import { useSession } from 'next-auth/react';
import { useCallback } from 'react';
import { toast } from 'sonner';

import useGlobalStore from 'store/global';
import useImaginationsStore from 'store/imaginations';
import { getImageDimensions, uploadFile } from 'utils/file';
import { ROUTES } from 'utils/routes';

import useCommon from './useCommon';
import { TOAST_DEFAULTS, VideoModelNames } from 'utils/config';
import { JobType, JobTypeMap, TODO } from '@/store/global.types';

const useImaginations = () => {
  const { wsId, iId, log } = useCommon();
  const { checkStripeRole } = useGlobalStore();

  const { data } = useSession();
  const uid = data?.user?.id;
  const { addIteration } = useImaginationsStore();

  const handleError = useCallback(
    (error: Error) => {
      Sentry.captureException(error);
      log('useImaginations -- handleError -- error:', error);

      toast('Something went wrong. Please try again.', {
        ...TOAST_DEFAULTS,
      });
    },
    [log]
  );

  const onGenerateAudio = useCallback(
    async (props: TODO) => {
      try {
        if (!checkStripeRole()) return;

        await addIteration(
          wsId,
          {
            ...props,
            type: JobType.metaMusicgen,
            ...(iId && { iId }),
          },
          uid
        );
      } catch (error) {
        handleError(error);
      }
    },
    [checkStripeRole, addIteration, wsId, iId, uid, handleError]
  );

  const onUpscale = useCallback(
    async (props: TODO) => {
      try {
        if (!checkStripeRole()) return;

        await addIteration(
          wsId,
          {
            ...props,
            type: JobType.sdUpscale,
            ...(iId && { iId }),
          },
          uid
        );
      } catch (error) {
        handleError(error);
      }
    },
    [checkStripeRole, addIteration, wsId, iId, uid, handleError]
  );

  const onGenerateVideo = useCallback(
    async (props: TODO) => {
      try {
        if (!checkStripeRole()) return;
        const { type, model } = props;
        const modelType =
          model === VideoModelNames.ray16 || model === VideoModelNames.ray2
            ? JobType.luma
            : model === VideoModelNames.gen3turbo
            ? JobType.runwaymlGen3Turbo
            : JobType.runwaymlGen3;

        await addIteration(
          wsId,
          {
            ...props,
            type: type || modelType,
            ...(iId && { iId }),
          },
          uid
        );
      } catch (error) {
        handleError(error);
        throw error;
      }
    },
    [checkStripeRole, addIteration, wsId, iId, uid, handleError]
  );

  const onGenerateImage = useCallback(
    async (props: TODO) => {
      try {
        if (!checkStripeRole()) return;

        let type;
        if (props.type) {
          type = props.type;
        } else {
          const model = props.model;
          type = JobTypeMap[model];
        }

        if (!type) throw new Error('Invalid model');

        addIteration(
          wsId,
          {
            ...props,
            type,
            ...(iId && { iId }),
          },
          uid
        );
      } catch (error) {
        handleError(error);
      }
    },
    [checkStripeRole, addIteration, wsId, iId, uid, handleError]
  );

  const onDescribe = useCallback(
    async (props: TODO) => {
      if (!checkStripeRole()) return;

      const { title, shdDescribe, image } = props;

      const dimensions = await getImageDimensions(image);

      if (!dimensions.width || !dimensions.height)
        throw new Error('Image dimensions not found');

      const imageUrl = await uploadFile({ wsId, file: image });

      await addIteration(
        wsId,
        {
          title,
          shdDescribe,
          imageUrl,
          urls: [
            {
              url: imageUrl,
              width: dimensions.width,
              height: dimensions.height,
            },
          ],
          width: dimensions.width,
          height: dimensions.height,
          type: JobType.describe,
          ...(iId && { iId }),
        },
        uid
      );
    },
    [checkStripeRole, wsId, addIteration, iId, uid]
  );

  const onAddDescribe = useCallback(
    async (props: TODO) => {
      if (!checkStripeRole()) return;

      const { title, imageUrl, width, height } = props;

      await addIteration(
        wsId,
        {
          title,
          imageUrl,
          shdDescribe: true,
          urls: [
            {
              url: imageUrl,
              width,
              height,
            },
          ],
          width,
          height,
          type: JobType.describe,
          ...(iId && { iId }),
        },
        uid
      );
    },
    [addIteration, checkStripeRole, iId, uid, wsId]
  );

  const onPromptGeneration = useCallback(
    async (props: TODO) => {
      if (!checkStripeRole()) return;

      await addIteration(
        wsId,
        {
          ...props,
          ...(iId && { iId }),
        },
        uid
      );
    },
    [addIteration, checkStripeRole, iId, uid, wsId]
  );

  return {
    onGenerateImage,
    onGenerateAudio,
    onGenerateVideo,
    onDescribe,
    onUpscale,
    onAddDescribe,
    onPromptGeneration,
  };
};

export default useImaginations;
