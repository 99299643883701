import { AnimatePresence } from 'framer-motion';
import { useSession } from 'next-auth/react';
import React, { PropsWithChildren, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';

import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import PageView from 'components/pageViews/PageView/PageView';
import { Toaster } from 'components/ui/sonner';
import useCommon from 'hooks/useCommon';
import { firebaseAuth, syncFirebaseAuth } from 'utils/api/firebase';
import { isAccountPage, isDashboardPage, isWsPage } from 'utils/routes';

import LayoutCommandDialog from './LayoutCommandDialog';
import LayoutDescribeDialog from './LayoutDescribeDialog';
import LayoutGenerateDialog from './LayoutGenerateDialog';
import Intercom from './LayoutManager.intercom';
import LayoutSharedForms from './LayoutManager.sharedForms';
import LayoutTrialDialog from './LayoutTrialDialog';
import MainSync from './Main.sync';
import MainSubscription from './Main.sync.subscription';
import useGlobalStore from '@/store/global';
import MainSyncCollections from './Main.sync.collections';

export interface MainProps {}

const defaultProps: Partial<MainProps> = {};

const Main: React.FC<PropsWithChildren<MainProps>> = ({ children }) => {
  const { router, wsId } = useCommon();
  const { setSuperAdmin, setAdmin, setInternal } = useGlobalStore();

  const { data: session, status } = useSession();
  const [user, loading] = useAuthState(firebaseAuth);

  // next-auth session user id
  const uid = user?.uid;
  const uemail = user?.email;

  const isSuperAdmin = session?.user?.isSuperAdmin;
  const isAdmin = session?.user?.isAdmin;
  const isInternal = session?.user?.isInternal;

  const isUserPage =
    isAccountPage(router) || isDashboardPage(router) || isWsPage(router);
  const isLoading = status === 'loading' || loading;

  useEffect(() => {
    if (isLoading) return;
    syncFirebaseAuth(session);
  }, [isLoading, session]);

  useEffect(() => {
    setSuperAdmin(isSuperAdmin);
    setAdmin(isAdmin);
    setInternal(isInternal);
  }, [isSuperAdmin, setSuperAdmin, isAdmin, setAdmin, isInternal, setInternal]);

  if ((isUserPage && !user) || isLoading)
    return <LoadingScreen isVisible refresh={!session && !user} />;

  return (
    <>
      <AnimatePresence mode="wait" initial={true}>
        <PageView {...{ wsId, uid }}>{children}</PageView>
      </AnimatePresence>
      {/* Subscription */}
      {wsId && <MainSubscription wsId={wsId} />}
      {/* Collections */}
      {wsId && <MainSyncCollections wsId={wsId} />}
      {/* User Page */}
      {isUserPage && wsId && uemail && (
        <MainSync {...{ wsId, uid, uemail, isSuperAdmin }} />
      )}
      {/* Dialogs */}
      {isUserPage && (
        <>
          <LayoutCommandDialog />
          <LayoutGenerateDialog />
          <LayoutDescribeDialog />
          <LayoutTrialDialog />
          {isDashboardPage(router) && <LayoutSharedForms />}
        </>
      )}
      <Toaster richColors />
      <Intercom />
    </>
  );
};

Main.defaultProps = defaultProps;

export default Main;
