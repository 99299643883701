import {
  addDoc,
  collection,
  doc,
  DocumentData,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  QueryDocumentSnapshot,
  serverTimestamp,
  startAfter,
  updateDoc,
} from 'firebase/firestore';
import _ from 'lodash';
import create from 'zustand';

import { toast } from 'components/ui/use-toast';
import { db, updateRecents } from 'utils/api/firebase';

import { TODO } from './global.types';
import { Project } from './projects.types';
import { getWsIdFromPath, uploadFile } from '@/utils/file';

export interface ProjectsStoreType {
  initialLoading: boolean;
  setInitialLoading: (initialLoading: boolean) => void;
  projects: Project[];
  syncProject: (project: Project) => void;

  lastVisible: QueryDocumentSnapshot<DocumentData, DocumentData>;
  setLastVisible: (
    lastVisible: QueryDocumentSnapshot<DocumentData, DocumentData>
  ) => void;

  hasMore: boolean;
  setHasMore: (hasMore: boolean) => void;

  limitCount: number;

  getProjects: (wsId: string, uid: string, all?: boolean) => void;

  getProject: (wsId: string, projectId: string) => TODO;

  createProject: (wsId: string, uid: string, data: TODO) => void;

  updateProject: (docRef: TODO, data: TODO, uid?: string) => void;

  getProjectPrivilege: (
    wsId: string,
    projectId: string,
    uid: string,
    wsEmail: string,
    uEmail: string
  ) => TODO;
}

const useProjectsStore = create<ProjectsStoreType>((set, get) => ({
  initialLoading: true,
  setInitialLoading: initialLoading => {
    set(() => ({ initialLoading }));
  },
  projects: [],
  syncProject: project => {
    set(({ projects }) => {
      const projectIndex = projects.findIndex(t => t.id === project.id);
      if (projectIndex === -1) return { projects: [...projects, project] };
      const updatedProjects = projects.map((t, index) =>
        index === projectIndex ? { ...t, ...project } : t
      );

      return {
        projects: _.sortBy(updatedProjects, ['name']),
      };
    });
  },

  lastVisible: null,
  setLastVisible: lastVisible => {
    set(() => ({ lastVisible }));
  },

  hasMore: true,
  setHasMore: hasMore => {
    set(() => ({ hasMore }));
  },

  limitCount: 25,

  getProjects: async (wsId, uid, all = false) => {
    if (!wsId || !uid) return;
    const {
      limitCount,
      lastVisible,
      syncProject,
      hasMore,
      setHasMore,
      setLastVisible,
      getProjects,
      setInitialLoading,
    } = get();

    if (!hasMore) return;

    // init q
    let q = query(collection(db, 'users', wsId, 'projects'));
    // add order desc and limit
    q = query(
      q,
      orderBy('pinned', 'desc'),
      orderBy('createdAt', 'desc'),
      limit(limitCount)
    );
    if (lastVisible) {
      q = query(q, startAfter(lastVisible));
    }
    const documentSnapshots = await getDocs(q);
    setInitialLoading(false);
    documentSnapshots.forEach(doc => {
      syncProject({ id: doc.id, ref: doc.ref, ...doc.data() });
    });
    if (documentSnapshots.empty) setHasMore(false);
    if (documentSnapshots.size) {
      setLastVisible(documentSnapshots.docs[documentSnapshots.size - 1]);
      if (all) getProjects(wsId, uid, all);
    }
  },

  getProject: async (wsId, projectId) => {
    if (!wsId || !projectId) return;
    const { projects, syncProject } = get();
    const syncedProject = projects.find(p => p.id === projectId);
    if (syncedProject) return syncedProject;
    const docRef = doc(db, 'users', wsId, 'projects', projectId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const project = { id: docSnap.id, ref: docSnap.ref, ...docSnap.data() };
      syncProject(project);
      return project;
    }
  },

  createProject: async (wsId, uid, data) => {
    try {
      if (!wsId) throw new Error('Workspace ID is required.');
      const { logo, ...restProps } = data;
      const logoUrl = await uploadFile({ wsId, file: logo });
      const projectDocRef = await addDoc(
        collection(db, 'users', wsId, 'projects'),
        {
          ...restProps,
          ...(logoUrl && { logoUrl }),
          pinned: [],
          createdBy: uid,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        }
      );
      updateRecents({ uid, path: projectDocRef.path });
    } catch (error) {
      console.log('useProjectsStore -- createProject -- error:', error);
      toast({
        title: 'Something went wrong. Please try again.',
      });
    }
  },

  updateProject: async (docRef, data, uid) => {
    const { logo, ...restProps } = data;
    try {
      const wsId = getWsIdFromPath(docRef.path);
      const logoUrl = await uploadFile({ wsId, file: logo });

      await updateDoc(docRef, {
        ...restProps,
        ...(logoUrl && { logoUrl }),
        ...(uid && { updatedBy: uid }),
        updatedAt: serverTimestamp(),
      });
      updateRecents({ uid, path: docRef.path });
    } catch (error) {
      console.log('useProjectsStore -- updateProject -- error:', error);
      toast({
        title: 'Something went wrong. Please try again.',
      });
    }
  },

  getProjectPrivilege: (wsId, projectId, uid, wsEmail, uEmail) => {
    const privilege = {
      role: 'unknown',
      canView: false, // can read project
      canEdit: false, // can edit project
      canShare: false, // can edit / share project
    };
    if (!wsId || !projectId || !uid || !wsEmail || !uEmail) return privilege;
    const { projects } = get();
    const project = projects.find(p => p.id === projectId);
    if (!project) return privilege;

    const {
      members = [],
      supervisors = [],
      leaders = [],
      createdBy,
      isArchive = false,
    } = project;

    if (isArchive) return privilege;

    if (members.includes(uEmail)) {
      privilege.role = 'member';
      privilege.canView = true;
    }
    if (supervisors.includes(uEmail)) {
      privilege.role = 'supervisior';
      privilege.canView = true;
      privilege.canEdit = true;
    }
    if (
      leaders.includes(uEmail) ||
      wsId === uid ||
      createdBy === uid ||
      wsEmail === uEmail
    ) {
      privilege.role = 'leader';
      privilege.canView = true;
      privilege.canEdit = true;
      privilege.canShare = true;
    }

    return privilege;
  },
}));

export default useProjectsStore;
