import useSubscriptions from '@/hooks/useSubscriptions';
import useGlobalStore from '@/store/global';
import { ProductType } from '@/utils/api/stripe';
import { PropsWithChildren, useEffect } from 'react';

interface MainSubscriptionProps {
  wsId: string;
}

const MainSubscription: React.FC<PropsWithChildren<MainSubscriptionProps>> = ({
  children,
  wsId,
}) => {
  const { setStripeRole, setWasCustomer } = useGlobalStore();
  const { stripeRole, wasCustomer } = useSubscriptions({ wsId });

  useEffect(() => {
    setStripeRole(stripeRole as ProductType);
    setWasCustomer(wasCustomer);
  }, [setStripeRole, setWasCustomer, stripeRole, wasCustomer]);

  return <>{children}</>;
};

export default MainSubscription;
