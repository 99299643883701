import { collection, doc, query, where } from 'firebase/firestore';
import _ from 'lodash';
import React, { PropsWithChildren, useMemo, useState } from 'react';
import { useEffect } from 'react';
import { useCollection, useDocument } from 'react-firebase-hooks/firestore';

import useUsersStore from 'store/users';
import { db } from 'utils/api/firebase';
import { ROUTES } from 'utils/routes';

import InviteDialog from './Main.inviteDialog';
import Notifications from './Main.notifications';

export interface MainSyncProps {
  wsId: string;
  uid: string;
  uemail: string;
  isSuperAdmin: boolean;
}

const MainSync: React.FC<PropsWithChildren<MainSyncProps>> = ({
  children,
  wsId,
  uid,
  uemail,
  isSuperAdmin,
}) => {
  const { getUser, updateMemberWorkspaces, updateUser } = useUsersStore();

  // workspace
  const [ws] = useDocument(doc(db, 'users', wsId), {
    snapshotListenOptions: { includeMetadataChanges: true },
  });
  useEffect(() => {
    if (ws) getUser(ws.id, true);
  }, [ws, getUser]);

  // user
  const [user] = useDocument(doc(db, 'users', uid), {
    snapshotListenOptions: { includeMetadataChanges: true },
  });
  useEffect(() => {
    if (user) getUser(user.id, true);
  }, [user, getUser]);

  // sync memberWorkspaces
  const [memberWorkspaces] = useCollection(
    query(
      collection(db, 'users'),
      where('memberIds', 'array-contains', uemail)
    ),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  useEffect(() => {
    if (!memberWorkspaces) return;
    const memberWorkspaceIds = memberWorkspaces.docs.map(doc => doc.id);
    updateMemberWorkspaces(
      uemail,
      memberWorkspaces.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }))
    );

    if (wsId !== uid && !memberWorkspaceIds.includes(wsId) && !isSuperAdmin)
      updateUser(uid, { 'user.defaultWorkspace': uid }, ROUTES.WORKSPACE);
  }, [
    isSuperAdmin,
    memberWorkspaces,
    uemail,
    uid,
    updateMemberWorkspaces,
    updateUser,
    wsId,
  ]);

  return (
    <>
      <Notifications {...{ uid }} />
      <InviteDialog {...{ wsId, uid, uemail }} />
      {children}
    </>
  );
};

export default MainSync;
