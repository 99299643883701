import { TODO } from '@/store/global.types';
import { loadStripe, Stripe as StripeClient } from '@stripe/stripe-js';
import getNextConfig from 'next/config';
import { llmModels } from './common';

const env = getNextConfig().publicRuntimeConfig;

let stripePromise: Promise<StripeClient | null>;

export const stripeClient = async () => {
  if (!stripePromise) {
    stripePromise = loadStripe(env.STRIPE_PUBLISHABLE_KEY);
  }
  return stripePromise;
};

export enum ProductType {
  EKEssential = 'ek_essential',
  EKPro = 'ek_pro',
  EKStudio = 'ek_studio',
  EKEnterprise = 'ek_enterprise',
  // legacy
  Basic = 'basic',
  Standard = 'standard',
  Pro = 'pro',
  Studio = 'studio',
  Free = 'free',
}

export const validProducts = [
  ProductType.EKEssential,
  ProductType.EKPro,
  ProductType.EKStudio,
  ProductType.EKEnterprise,
] as const;

// legacy
export const validProductsLegacy = [
  ProductType.Basic,
  ProductType.Standard,
  ProductType.Pro,
  ProductType.Studio,
] as const;

export const isValidProduct = (
  productType: ProductType | string | null | undefined
): boolean => {
  if (!productType) return false;
  return validProducts.includes(productType as any);
};

export const isValidProductLegacy = (
  productType: ProductType | string | null | undefined
): boolean => {
  if (!productType) return false;
  return validProductsLegacy.includes(productType as any);
};

export const productNames = {
  [ProductType.EKEssential]: 'Essential',
  [ProductType.EKPro]: 'Pro',
  [ProductType.EKStudio]: 'Studio',
  [ProductType.EKEnterprise]: 'Enterprise',
  // legacy
  [ProductType.Basic]: 'Basic',
  [ProductType.Standard]: 'Standard',
  [ProductType.Pro]: 'Pro',
  [ProductType.Studio]: 'Studio',
};

export const RoleMinMembers = {
  [ProductType.EKEssential]: 1,
  [ProductType.EKPro]: 1,
  [ProductType.EKStudio]: 1,
  [ProductType.EKEnterprise]: 1,
  // legacy
  [ProductType.Basic]: 1,
  [ProductType.Standard]: 1,
  [ProductType.Pro]: 1,
  [ProductType.Studio]: 1,
};

export const RoleMaxMembers = {
  [ProductType.EKEssential]: 1,
  [ProductType.EKPro]: 10,
  [ProductType.EKStudio]: 25,
  [ProductType.EKEnterprise]: 100,
  // legacy
  [ProductType.Basic]: 1,
  [ProductType.Standard]: 2,
  [ProductType.Pro]: 5,
  [ProductType.Studio]: 10,
};

export const RoleMaxMembersDescription = {
  [ProductType.EKEssential]:
    'Please upgrade to Pro or Studio plan to add members.',
  [ProductType.EKPro]: 'Please upgrade to Studio plan to add more members.',
  [ProductType.EKStudio]:
    'You have reached the maximum number of members. Please contact support@euryka.ai to add more members.',
  [ProductType.EKEnterprise]: 'You have reached the maximum number of members.',
  // legacy
  [ProductType.Basic]:
    'You have reached the maximum number of members. Please upgrade to Pro or Studio plan to add more members.',
  [ProductType.Standard]:
    'You have reached the maximum number of members. Please upgrade to Pro or Studio plan to add more members.',
  [ProductType.Pro]:
    'You have reached the maximum number of members. Please upgrade to Pro or Studio plan to add more members.',
  [ProductType.Studio]:
    'You have reached the maximum number of members. Please upgrade to Studio plan to add more members.',
};

export const RoleUpgradeDescription = {
  [ProductType.EKEssential]:
    'Upgrade to Essential to unlock basic AI-powered creativity tools.',
  [ProductType.EKPro]:
    'Unlock advanced AI models and collaborative features with Pro.',
  [ProductType.EKStudio]:
    'Get access to premium AI tools and priority support with Studio.',
  [ProductType.EKEnterprise]:
    'Experience our most powerful AI capabilities with Enterprise.',
  // legacy
  [ProductType.Basic]:
    'Upgrade to Basic to get started with essential features.',
  [ProductType.Standard]: 'Enhance your experience with Standard features.',
  [ProductType.Pro]:
    'Upgrade to Pro for advanced capabilities and team features.',
  [ProductType.Studio]:
    'Get our complete suite of professional tools with Studio.',
};

// Shorter variants for toast messages
export const RoleUpgradeShortDescription = {
  [ProductType.EKEssential]: 'Unlock basic AI tools',
  [ProductType.EKPro]: 'Access advanced AI models',
  [ProductType.EKStudio]: 'Gain premium AI features',
  [ProductType.EKEnterprise]: 'Experience enterprise-level AI',
  // legacy
  [ProductType.Basic]: 'Get started with basic features',
  [ProductType.Standard]: 'Enhance your capabilities',
  [ProductType.Pro]: 'Unlock advanced tools',
  [ProductType.Studio]: 'Access our complete toolset',
};

export const productDescription = {
  [ProductType.EKEssential]:
    'Ignite Your Creativity. Perfect for individuals and small business ready to harness the power of AI.',
  [ProductType.EKPro]:
    'Amplify Your Collaboration. Ideal for dynamic duos and growing teams.',
  [ProductType.EKStudio]:
    'Accelerate Your Innovation. Empower your growing team with a comprehensive suite of AI tools.',
  [ProductType.EKEnterprise]:
    ' Elevate Your Enterprise. The ultimate AI powerhouse for larger teams and agencies. Tailored AI solutions at scale, dedicated support.',
  // legacy
  [ProductType.Basic]: '',
  [ProductType.Standard]: '',
  [ProductType.Pro]: '',
  [ProductType.Studio]: '',
};

export const productFeaturesTitle = {
  [ProductType.EKEssential]: '',
  [ProductType.EKPro]: 'Everything in Essential: plus',
  [ProductType.EKStudio]: 'Everything in Pro: plus',
  [ProductType.EKEnterprise]: 'Everything in Studio: plus',
  // legacy
  [ProductType.Basic]: '',
  [ProductType.Standard]: '',
  [ProductType.Pro]: '',
  [ProductType.Studio]: '',
};

export const productFeatures = {
  [ProductType.EKEssential]: [
    '1 user seat',
    'Brands: Up to 3',
    'Chat Models: OpenAI, Anthropic, Meta, Gemini, Mistral, DeepSeek',
    'Image Models: Stable Diffusion, Flux, Flux Ultra, Dall-E, Ideogram, Recraft',
    'Video Models: Luma',
    'Custom Characters / Personas: 3',
    'Storage: 25 GB',
    'Tools: Essential AI-powered creativity tools',
  ],
  [ProductType.EKPro]: [
    `Up to ${RoleMaxMembers[ProductType.EKPro]} seats`,
    'Brands: Up to 10',
    'Chat Models: OpenAl (01), OpenAl (01 mini), OpenAl (03 mini), Anthropic (opus), DeepSeek (r1)',
    'Video Models: Luma Ray, RunwayML',
    'Custom Characters / Personas: 10',
    'Storage: 100 GB',
    'Collaborative: Threads, Documents',
    'Beta features access',
  ],
  [ProductType.EKStudio]: [
    `Up to ${RoleMaxMembers[ProductType.EKStudio]} seats`,
    'Brands: Up to 25',
    'Chat Models: Access to new LLMs',
    'Image Models: Access to new models',
    'Custom Characters / Personas: 25',
    'Storage: 500 GB',
    'Priority Support',
  ],
  [ProductType.EKEnterprise]: [
    'Brands: Unlimited',
    'Chat Models: Priority access to new LLMs',
    'Image Models: Priority access to new models',
    'Custom Characters / Personas: 50',
    'Storage: Unlimited',
    'Dedicated Account Manager',
    'API Access',
    'Premium Support',
  ],
  // legacy
  [ProductType.Basic]: [],
  [ProductType.Standard]: [],
  [ProductType.Pro]: [],
  [ProductType.Studio]: [],
};

export const featureRoleMap = {
  [llmModels['openai-gpt-4.5-preview']]: ProductType.EKPro,
  [llmModels['openai-o1']]: ProductType.EKPro,
  [llmModels['openai-o1-mini']]: ProductType.EKPro,
  [llmModels['openai-o3-mini']]: ProductType.EKPro,
  [llmModels['gemini-2.5-pro-exp-03-25']]: ProductType.EKPro,
  [llmModels['anthropic-opus']]: ProductType.EKPro,
  [llmModels['deepseek-r1']]: ProductType.EKPro,
};

export const hasMinRole = (
  stripeRole: ProductType,
  requiredMinRole: ProductType
): boolean => {
  if (!stripeRole || stripeRole === ProductType.Free) return false;

  // Map legacy roles to new roles for comparison
  const roleMapping = {
    // Legacy to new
    [ProductType.Basic]: ProductType.EKEssential,
    [ProductType.Standard]: ProductType.EKEssential,
    [ProductType.Pro]: ProductType.EKPro,
    [ProductType.Studio]: ProductType.EKStudio,
  };

  // Normalize roles to the new system
  let normalizedUserRole = stripeRole;
  let normalizedRequiredRole = requiredMinRole;

  // Convert legacy user role to new system if needed
  if (isValidProductLegacy(stripeRole)) {
    normalizedUserRole = roleMapping[stripeRole] || ProductType.Free;
  }

  // Convert legacy required role to new system if needed
  if (isValidProductLegacy(requiredMinRole)) {
    normalizedRequiredRole = roleMapping[requiredMinRole] || ProductType.Free;
  }

  // If either role couldn't be normalized, it's invalid
  if (
    !isValidProduct(normalizedUserRole) ||
    !isValidProduct(normalizedRequiredRole)
  )
    return false;

  // Now both roles are in the new system, so we can compare directly
  const userRoleIndex = validProducts.indexOf(normalizedUserRole as TODO);
  const requiredRoleIndex = validProducts.indexOf(
    normalizedRequiredRole as TODO
  );

  // Check if current role is >= required role
  return userRoleIndex >= requiredRoleIndex;
};

export const hasFeatureAccess = ({
  stripeRole,
  feature,
}: {
  stripeRole: ProductType;
  feature: string;
}) => {
  const requiredMinRole = featureRoleMap[feature] || ProductType.EKEssential;
  return {
    hasAccess: hasMinRole(stripeRole, requiredMinRole),
    requiredMinRole,
  };
};
