import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  DocumentData,
  getDoc,
  getDocs,
  limit,
  or,
  orderBy,
  query,
  QueryDocumentSnapshot,
  serverTimestamp,
  setDoc,
  startAfter,
  updateDoc,
  where,
} from 'firebase/firestore';
import { toast } from 'sonner';
import create from 'zustand';

import { db } from 'utils/api/firebase';
import { TOAST_DEFAULTS } from 'utils/config';

import { TODO } from './global.types';
import { Thread } from './threads.types';
import API from 'utils/api';
import { llmModels } from '@/utils/api/common';
const IS_DEBUG = process.env.IS_DEBUG && false;

export interface ThreadsStoreType {
  initialLoading: boolean;
  setInitialLoading: (initialLoading: boolean) => void;

  threads: Thread[];
  syncThread: (thread: Thread) => void;

  lastVisible: QueryDocumentSnapshot<DocumentData, DocumentData>;
  setLastVisible: (
    lastVisible: QueryDocumentSnapshot<DocumentData, DocumentData>
  ) => void;

  hasMore: boolean;
  setHasMore: (hasMore: boolean) => void;

  limitCount: number;

  getThreads: (
    wsId: string,
    uid: string,
    uemail: string,
    all?: boolean
  ) => void;

  getThread: (wsId: string, threadId: string) => TODO;

  createThread: (wsId: string, uid: string, data: TODO, tId?: string) => void;

  updateThread: (docRef: TODO, data: TODO, uid?: string) => void;

  createQuickStartMessage: (
    wsId: string,
    tId: string,
    uid: string,
    data: TODO
  ) => void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useThreadsStore = create<ThreadsStoreType>((set, get) => ({
  initialLoading: true,
  setInitialLoading: initialLoading => {
    set(() => ({ initialLoading }));
  },

  threads: [],
  syncThread: thread => {
    set(({ threads }) => {
      const threadIndex = threads.findIndex(t => t.id === thread.id);
      if (threadIndex === -1) return { threads: [...threads, thread] };
      const updatedThreads = threads.map((t, index) =>
        index === threadIndex ? thread : t
      );
      return {
        threads: updatedThreads.sort((a, b) => b.createdAt - a.createdAt),
      };
    });
  },
  hasMore: true,
  setHasMore: hasMore => {
    set(() => ({ hasMore }));
  },
  limitCount: 13,

  lastVisible: null,
  setLastVisible: lastVisible => {
    set(() => ({ lastVisible }));
  },

  getThreads: async (wsId, uid, uemail, all = false) => {
    if (!wsId || !uid || !uemail) return;
    if (IS_DEBUG)
      console.log(
        'useThreadsStore -- getThreads -- wsId:',
        wsId,
        'uid:',
        uid,
        'uemail:',
        uemail
      );
    const {
      limitCount,
      lastVisible,
      syncThread,
      hasMore,
      setHasMore,
      setLastVisible,
      getThreads,
      setInitialLoading,
    } = get();

    if (!hasMore) return;

    // init q
    let q = query(
      collection(db, 'users', wsId, 'threads'),
      or(
        where('createdBy', '==', uid),
        where('pinned', 'array-contains', uid),
        where('readers', 'array-contains', uemail),
        where('editors', 'array-contains', uemail)
      )
    );
    // add order desc and limit
    q = query(
      q,
      orderBy('pinned', 'desc'),
      orderBy('createdAt', 'desc'),
      limit(limitCount)
    );

    // has no more starred
    if (lastVisible) {
      q = query(q, startAfter(lastVisible));
    }
    const documentSnapshots = await getDocs(q);
    setInitialLoading(false);
    if (IS_DEBUG)
      console.log(
        'useThreadsStore -- getThreads -- documentSnapshots:',
        documentSnapshots.size
      );
    documentSnapshots.forEach(doc => {
      syncThread({ id: doc.id, ref: doc.ref, ...doc.data() });
    });
    if (documentSnapshots.empty || documentSnapshots.size < limitCount)
      setHasMore(false);

    if (documentSnapshots.size) {
      setLastVisible(documentSnapshots.docs[documentSnapshots.size - 1]);
      if (all) getThreads(wsId, uid, uemail, all);
    }
  },

  getThread: async (wsId, threadId) => {
    if (!wsId || !threadId) return;
    const { threads, syncThread } = get();
    const syncedThread = threads.find(t => t.id === threadId);
    if (syncedThread) return syncedThread;
    const docRef = doc(db, 'users', wsId, 'threads', threadId);
    const docSnap = await getDoc(docRef);
    const thread = docSnap.exists()
      ? { id: docSnap.id, ref: docSnap.ref, ...docSnap.data() }
      : null;
    if (thread) syncThread(thread);
    return thread;
  },

  createThread: async (wsId, uid, data, tId) => {
    try {
      if (!wsId) throw new Error('Workspace ID is required.');
      const threadData = {
        ...data,
        pinned: [],
        readers: [],
        editors: [],
        intents: [],
        createdBy: uid,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      };
      if (tId) {
        await setDoc(doc(db, 'users', wsId, 'threads', tId), threadData);
        return;
      }
      await addDoc(collection(db, 'users', wsId, 'threads'), threadData);
    } catch (error) {
      if (IS_DEBUG)
        console.log('useThreadsStore -- createThread -- error:', error);
      toast.error('Something went wrong. Please try again.', TOAST_DEFAULTS);
    }
  },

  createQuickStartMessage: async (wsId, tId, uid, data) => {
    if (!wsId || !tId || !uid || !data) return;

    if (IS_DEBUG)
      console.log(
        'threads -- createQuickStartMessage -- wsId:',
        wsId,
        'tId:',
        tId,
        'uid:',
        uid,
        'data:',
        data
      );
    const messageRef = await addDoc(
      collection(db, 'users', wsId, 'threads', tId, 'messages'),
      {
        ...data,
        role: 'user',
        uid,
        model: llmModels.anthropic,
        createdAt: serverTimestamp(),
      }
    );
    API.createMessage({ wsId, tId, mId: messageRef.id });
  },

  updateThread: async (docRef, data, uid) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { starred, pinned, ...restProps } = data;
    try {
      if (IS_DEBUG)
        console.log(
          'useThreadsStore -- updateThread -- docRef.path:',
          docRef.path,
          'data:',
          data,
          'uid:',
          uid
        );
      await updateDoc(docRef, {
        ...restProps,
        ...((starred || pinned) && {
          ...(starred !== undefined && uid
            ? {
                starred:
                  starred === 'remove' ? arrayRemove(uid) : arrayUnion(uid),
              }
            : {}),
          ...(pinned !== undefined && uid
            ? {
                pinned:
                  pinned === 'remove' ? arrayRemove(uid) : arrayUnion(uid),
              }
            : {}),
        }),
        ...(uid && { updatedBy: uid }),
        updatedAt: serverTimestamp(),
      });
    } catch (error) {
      console.log('useThreadsStore -- updateThread -- error:', error);
      toast.error('Something went wrong. Please try again.', TOAST_DEFAULTS);
    }
  },
}));

export default useThreadsStore;
