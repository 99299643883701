import {
  CalendarIcon,
  Check,
  CheckIcon,
  ImagePlus,
  Pencil,
  Trash,
  X,
} from 'lucide-react';
import { Badge } from './badge';
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from './form';
import { Input } from './input';
import { Textarea } from './textarea';
import { cn } from '@/lib/utils';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import {
  Select as SelectCn,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from './select';
import { TODO } from '@/store/global.types';
import { Fragment, useRef, useState } from 'react';
import { Slider } from './slider';
import { Switch } from './switch';
import { Popover, PopoverContent, PopoverTrigger } from './popover';
import { Button } from './button';
import { Calendar } from './calendar';
import { format } from 'date-fns';
import { ColorInput } from './color-input';
import _ from 'lodash';

export const FormInput = props => {
  const {
    form,
    label,
    description,
    name,
    placeholder,
    disabled = false,
    adornment,
    type = 'text',
  } = props;
  return (
    <FormField
      control={form.control}
      name={name}
      disabled={disabled}
      render={({ field }) => (
        <FormItem>
          {adornment ? (
            <div className={'flex items-center gap-2'}>
              <FormLabel>{label}</FormLabel>
              {adornment}
            </div>
          ) : (
            <FormLabel>{label}</FormLabel>
          )}
          <FormControl>
            <Input placeholder={placeholder} {...field} {...{ type }} />
          </FormControl>
          {description && <FormDescription>{description}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export const FormColorInput = props => {
  const {
    form,
    label,
    description,
    name,
    placeholder,
    disabled = false,
    showOpacity = true,
  } = props;
  const [open, setOpen] = useState(false);
  return (
    <FormField
      control={form.control}
      name={name}
      disabled={disabled}
      render={({ field }) => (
        <FormItem>
          <FormLabel>{label}</FormLabel>
          <FormControl>
            <Fragment>
              <ColorInput
                defaultValue={field.value}
                label={''}
                onChange={field.onChange}
                showOpacity={showOpacity}
              />
            </Fragment>
          </FormControl>
          {description && <FormDescription>{description}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export const FormTextarea = props => {
  const {
    form,
    label,
    description,
    name,
    placeholder,
    minRows = 3,
    maxRows = 8,
    disabled = false,
    adornment,
    endAdornment,
    onChange,
  } = props;

  return (
    <FormField
      control={form.control}
      name={name}
      disabled={disabled}
      render={({ field }) => (
        <FormItem>
          {adornment || endAdornment ? (
            <div className={'flex items-center gap-2'}>
              <FormLabel>{label}</FormLabel>
              {adornment}
              {endAdornment}
            </div>
          ) : (
            <FormLabel>{label}</FormLabel>
          )}
          <FormControl>
            <Textarea
              placeholder={placeholder}
              minRows={minRows}
              maxRows={maxRows}
              {...field}
              onChange={e => {
                field.onChange(e);
                onChange?.(e.target.value);
              }}
            />
          </FormControl>
          {description && <FormDescription>{description}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export const FormBadgeSelect = props => {
  const { form, label, description, name, options, disabled = false } = props;
  return (
    <FormField
      control={form.control}
      name={name}
      disabled={disabled}
      render={({ field }) => (
        <FormItem>
          <FormLabel>{label}</FormLabel>
          <FormControl>
            <div className="flex gap-2">
              {options.map(option => (
                <Badge
                  key={option}
                  variant={field.value === option ? 'default' : 'outline'}
                  className={cn('cursor-pointer')}
                  onClick={() => field.onChange(option)}
                >
                  {field.value === option && <Check className="w-4 h-4 mr-2" />}
                  {option}
                </Badge>
              ))}
            </div>
          </FormControl>
          {description && <FormDescription>{description}</FormDescription>}
        </FormItem>
      )}
    />
  );
};

export const FormBadge = props => {
  const { form, label, description, name, options, disabled = false } = props;
  return (
    <FormField
      control={form.control}
      name={name}
      disabled={disabled}
      render={({ field }) => (
        <FormItem>
          <FormLabel>{label}</FormLabel>
          <FormControl>
            <div className="flex gap-2">
              {options.map(({ key, label }) => (
                <Badge
                  key={key}
                  variant={field.value === key ? 'default' : 'outline'}
                  className={cn('rounded-full cursor-pointer')}
                  onClick={() => field.onChange(key)}
                >
                  {label}
                </Badge>
              ))}
            </div>
          </FormControl>
          {description && <FormDescription>{description}</FormDescription>}
        </FormItem>
      )}
    />
  );
};

export const FormTagsSelect = props => {
  const {
    form,
    label,
    description,
    name,
    options,
    placeholder,
    canCreate,
    closeMenuOnSelect = true,
    disabled = false,
    isMulti = true,
    className = '',
  } = props;

  const flatOptions =
    options?.length && options[0]?.options
      ? options.flatMap(option => option.options)
      : options || [];

  const Comp = canCreate ? CreatableSelect : Select;
  return (
    <FormField
      control={form.control}
      name={name}
      disabled={disabled}
      render={({ field }) => (
        <FormItem className={cn(!label && 'space-y-0 mb-0', className)}>
          {label && <FormLabel>{label}</FormLabel>}
          <FormControl>
            <Comp
              isDisabled={disabled}
              options={options}
              isMulti={isMulti}
              closeMenuOnSelect={closeMenuOnSelect}
              noOptionsMessage={() => null}
              placeholder={placeholder}
              value={(field.value || []).map(item => ({
                value: item,
                label:
                  flatOptions.find(
                    option => option.value === item || options.label === item
                  )?.label || item,
                name:
                  flatOptions.find(
                    option => option.value === item || options.label === item
                  )?.name || item,
              }))}
              onChange={(newValue, actionMeta) => {
                if (actionMeta.action === 'pop-value') {
                  const { removedValue } = actionMeta;
                  if (removedValue?.name) {
                    form.setValue(removedValue.name, '');
                  }
                }
                if (actionMeta.action === 'select-option') {
                  const { option } = actionMeta;
                  if (option?.name) {
                    const value = newValue.filter(
                      item => item.name !== option.name
                    );
                    value.push(option);
                    form.setValue(option.name, option.value);
                    field.onChange(value.map(item => item.value));
                    form.trigger(option.name);
                    return;
                  }
                }
                field.onChange(newValue.map(item => item.value));
              }}
              classNames={{
                control: (baseStyles, state) =>
                  cn(
                    '!bg-transparent !border !border-input !rounded-md !hover:border-input shadow-sm !py-1',
                    'focus-within:!outline-none focus-within:!ring-1 focus-within:!ring-ring'
                  ),
                menu: () => '!rounded-sm dark:!border dark:!bg-popover',
                input: () => 'text-sm !p-0 !m-0 !text-foreground',
                placeholder: () => 'text-sm !text-muted-foreground',
                valueContainer: () => 'flex flex-wrap gap-1',
                noOptionsMessage: () => 'text-sm text-muted-foreground',
                group: () => 'text-sm text-foreground',
                groupHeading: () =>
                  '!text-xs !text-muted-foreground !capitalize',
              }}
              components={{
                DropdownIndicator: () => null,
                ClearIndicator: () => null,
                IndicatorSeparator: () => null,
                MultiValue: ({ data, removeProps }) => (
                  <Badge variant="default" className="rounded-full pr-1.5">
                    {data.label}
                    {!disabled && (
                      <X
                        className="h-4 w-4 ml-0.5 cursor-pointer"
                        onMouseDown={e => {
                          e.stopPropagation();
                          removeProps.onClick();
                          form.setValue(
                            name,
                            field.value.filter(item => item !== data.value)
                          );
                          if (data.name) {
                            form.setValue(data.name, '');
                          }
                        }}
                      />
                    )}
                  </Badge>
                ),
                Option: ({ data, isSelected, isFocused, innerProps }) => (
                  <div
                    {...innerProps}
                    className={cn(
                      'px-3 py-1.5 rounded-sm text-sm hover:bg-muted-foreground/5 flex items-center',
                      isFocused && 'bg-muted-foreground/5'
                    )}
                  >
                    {data.label}
                    {isSelected && <CheckIcon className="ml-auto h-4 w-4" />}
                  </div>
                ),
              }}
              hideSelectedOptions={false}
            />
          </FormControl>
          {description && <FormDescription>{description}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export const FormSelect = props => {
  const {
    form,
    label,
    description,
    name,
    options,
    placeholder = '',
    disabled = false,
    className = '',
  } = props;
  return (
    <FormField
      control={form.control}
      name={name}
      disabled={disabled}
      render={({ field }) => (
        <FormItem className={className}>
          <FormLabel>{label}</FormLabel>
          <SelectCn onValueChange={field.onChange} defaultValue={field.value}>
            <FormControl>
              <SelectTrigger>
                <SelectValue placeholder={placeholder} />
              </SelectTrigger>
            </FormControl>
            <SelectContent>
              {options.map(option => (
                <SelectItem key={option.value} value={option.value}>
                  {option.label}
                </SelectItem>
              ))}
            </SelectContent>
          </SelectCn>
          {description && <FormDescription>{description}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export const FormImage = (props: TODO) => {
  const {
    form,
    label,
    description,
    name,
    imageUrl,
    setImageUrl,
    disabled = false,
    size = 'default',
  } = props;
  const ref = useRef<HTMLInputElement>(null);
  return (
    <FormField
      control={form.control}
      name={name}
      disabled={disabled}
      render={({ field }) => (
        <FormItem>
          <FormLabel>{label}</FormLabel>
          <FormControl>
            <div
              className={cn(
                'rounded-full bg-muted-foreground/5 text-muted-foreground grid group',
                size === 'small' ? 'w-16 h-16' : 'w-28 h-28'
              )}
            >
              {!imageUrl && !field.value && (
                <ImagePlus
                  className={cn(
                    'col-start-1 row-start-1 w-8 h-8 place-self-center text-muted-foreground',
                    size === 'small' ? 'w-4 h-4' : 'w-8 h-8'
                  )}
                  strokeWidth={1.5}
                />
              )}
              {imageUrl && (
                <img
                  src={imageUrl}
                  alt="Organization logo"
                  className="col-start-1 row-start-1 w-full h-full object-cover rounded-full aspect-square"
                />
              )}
              {field.value && (
                <img
                  src={
                    field.value instanceof File
                      ? URL.createObjectURL(field.value)
                      : ''
                  }
                  alt="logo"
                  className="col-start-1 row-start-1 w-full h-full object-cover rounded-full aspect-square"
                />
              )}
              {!disabled && (
                <div
                  className={cn(
                    'col-start-1 row-start-1 cursor-pointer',
                    size === 'small' ? 'w-16 h-16' : 'w-28 h-28'
                  )}
                  onClick={() => {
                    ref.current?.click();
                  }}
                />
              )}
              {(imageUrl || field.value) && !disabled && (
                <div className="col-start-1 row-start-1 w-6 h-6 justify-self-center self-end grid bg-muted rounded-full mb-1 pointer-events-none">
                  <Pencil className="w-3 h-3 place-self-center" />
                </div>
              )}
              <Input
                type={'file'}
                onChange={e => {
                  if (e.target.files?.[0]) {
                    field.onChange(e.target.files[0]);
                  }
                }}
                accept={'.jpg, .jpeg, .png'}
                className={
                  'col-start-1 row-start-1 w-full h-full cursor-pointer hidden'
                }
                ref={ref}
              />
              {imageUrl && !field.value && !disabled && (
                <div
                  className="col-start-1 row-start-1 w-6 h-6 justify-self-end grid bg-muted rounded-full invisible cursor-pointer group-hover:visible"
                  onClick={() => setImageUrl?.('')}
                >
                  <Trash className="w-3 h-3 place-self-center" />
                </div>
              )}
              {field.value && !disabled && (
                <div
                  className="col-start-1 row-start-1 w-6 h-6 justify-self-end grid bg-muted rounded-full invisible cursor-pointer group-hover:visible"
                  onClick={() => {
                    if (name === 'image' && form.getValues('imageEnd')) {
                      form.setValue('image', form.getValues('imageEnd'));
                      form.setValue('imageEnd', null);
                    } else {
                      form.setValue(name, null);
                    }
                  }}
                >
                  <X className="w-3 h-3 place-self-center" />
                </div>
              )}
            </div>
          </FormControl>
          {description && <FormDescription>{description}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export const FormSlider = props => {
  const {
    form,
    label,
    description,
    name,
    disabled = false,
    min = 0,
    max = 100,
    step = 1,
  } = props;
  return (
    <FormField
      control={form.control}
      name={name}
      disabled={disabled}
      render={({ field }) => (
        <FormItem>
          <FormLabel>{label}</FormLabel>
          <FormControl>
            <Slider
              defaultValue={[form.watch(name)]}
              min={min}
              max={max}
              step={step}
              onValueChange={([value]) => field.onChange(value)}
            />
          </FormControl>
          {description && <FormDescription>{description}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export const FormSwitch = props => {
  const { form, label, description, name, disabled = false, onChange } = props;
  return (
    <FormField
      control={form.control}
      name={name}
      disabled={disabled}
      render={({ field }) => (
        <FormItem>
          <FormLabel>{label}</FormLabel>
          <FormControl>
            <div>
              <Switch
                checked={field.value}
                onCheckedChange={e => {
                  field.onChange(e);
                  onChange?.(e);
                }}
              />
            </div>
          </FormControl>
          {description && <FormDescription>{description}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export const FormDatePicker = props => {
  const {
    form,
    label,
    description,
    name,
    disabled = false,
    minDate,
    maxDate,
  } = props;
  return (
    <FormField
      control={form.control}
      name={name}
      disabled={disabled}
      render={({ field }) => (
        <FormItem>
          <FormLabel>{label}</FormLabel>
          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <div>
                  <Button
                    variant={'outline'}
                    className={cn(
                      'bg-transparent text-left font-normal',
                      !field.value && 'text-muted-foreground'
                    )}
                    type="button"
                    disabled={disabled}
                  >
                    {field.value ? (
                      format(field.value, 'PPP')
                    ) : (
                      <span>Pick a date</span>
                    )}
                    <CalendarIcon className="ml-3 h-4 w-4 opacity-50" />
                  </Button>
                </div>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0" align="start">
              <Calendar
                mode="single"
                selected={field.value}
                onSelect={field.onChange}
                disabled={date =>
                  disabled ||
                  (maxDate && date > new Date(maxDate)) ||
                  (minDate && date < new Date(minDate))
                }
              />
            </PopoverContent>
          </Popover>
          {description && <FormDescription>{description}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export const FormInputUrl = props => {
  const { form, label, description, name, disabled = false, adornment } = props;
  return (
    <FormField
      control={form.control}
      name={name}
      disabled={disabled}
      render={({ field }) => (
        <FormItem>
          {adornment ? (
            <div className={'flex items-center gap-2'}>
              <FormLabel>{label}</FormLabel>
              {adornment}
            </div>
          ) : (
            <FormLabel>{label}</FormLabel>
          )}
          <FormControl>
            <Input
              placeholder={'Add a url...'}
              {...field}
              onChange={async e => {
                let value = e.target.value;
                if (!value || value === 'https://') {
                  await field.onChange('');
                  form.clearErrors(name);
                  return;
                }
                if (value && value.includes('http://')) {
                  value = _.replace(value, 'http://', 'https://');
                }
                if (value && value.includes('https://')) {
                  value = _.replace(value, 'https://', '');
                }
                if (value && !value.includes('://')) {
                  value = `https://${value}`;
                }
                field.onChange(value);
              }}
            />
          </FormControl>
          {description && <FormDescription>{description}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
    />
  );
};
