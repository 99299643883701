import { zodResolver } from '@hookform/resolvers/zod';
import { Loader2 } from 'lucide-react';
import React, { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as z from 'zod';

import { Badge } from 'components/ui/badge';
import { Button } from 'components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'components/ui/form';
import { useGlobalStore } from 'store';
import { cn } from 'utils';

import {
  FormBadge,
  FormImage,
  FormSwitch,
  FormTextarea,
} from '../ui/form.utils';
import {
  orienationsLuma,
  orienationsRunwaymlGen3,
  orienationsRunwaymlGen3Turbo,
  VideoModelNames,
  videoModelsOptions,
} from '@/utils/config';
import { useEffectOnce } from 'react-use';
import useImaginations from '@/hooks/useImaginations';
import _ from 'lodash';

export interface GenerateVideoProps {
  adornment?: React.ReactNode;
}

const defaultProps: Partial<GenerateVideoProps> = {};

const formSchema = z.object({
  prompt: z.string().nonempty({
    message: 'Prompt is required',
  }),
  enhancePrompt: z.boolean().optional(),
  model: z.enum([
    VideoModelNames.ray16,
    VideoModelNames.ray2,
    VideoModelNames.gen3turbo,
    VideoModelNames.gen3,
  ]),
  isLoop: z.boolean().optional(),
  orientation: z.string(),
  duration: z.number(),
  image: z.union([z.instanceof(File), z.undefined()]),
  imageEnd: z.union([z.instanceof(File), z.undefined()]),
});

const formDefaultValues: z.infer<typeof formSchema> = {
  prompt: '',
  enhancePrompt: true,
  model: VideoModelNames.ray16,
  isLoop: false,
  orientation: 'landscape',
  duration: 5,
};

const GenerateVideo: React.FC<GenerateVideoProps> = ({ adornment }) => {
  const [isLoading, setLoading] = useState(false);
  const { setGenerateOpen, generateProps, setGenerateProps } = useGlobalStore();
  const { onGenerateVideo } = useImaginations();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: formDefaultValues,
  });

  const formReset = useCallback(() => {
    form.reset(formDefaultValues);
    setGenerateProps(null);
  }, [form, setGenerateProps]);

  const onSubmit = useCallback(
    async (values: z.infer<typeof formSchema>) => {
      if (process.env.IS_DEBUG)
        console.log('LayoutGenerate -- onSubmit -- values:', values);
      setLoading(true);
      try {
        await onGenerateVideo(values);
        setGenerateOpen(false);
        formReset();
      } finally {
        setLoading(false);
      }
    },
    [formReset, onGenerateVideo, setGenerateOpen]
  );

  useEffectOnce(() => {
    form.reset({
      ...formDefaultValues,
      ..._.pick(generateProps, [
        'prompt',
        'modelVersion',
        'duration',
        'enhancePrompt',
      ]),
    });
  });

  const model = form.watch('model');
  const sizes = useMemo(() => {
    if (model === VideoModelNames.ray16 || model === VideoModelNames.ray2)
      return orienationsLuma;
    if (model === VideoModelNames.gen3turbo)
      return orienationsRunwaymlGen3Turbo;
    if (model === VideoModelNames.gen3) return orienationsRunwaymlGen3;
  }, [model]);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FormTextarea
          {...{
            form,
            name: 'prompt',
            label: 'Prompt',
            placeholder: 'Type a prompt',
            adornment,
            onChange: (value: string) =>
              setGenerateProps({
                ...generateProps,
                prompt: value,
              }),
          }}
        />
        <FormSwitch
          {...{
            form,
            name: 'enhancePrompt',
            label: 'Enhance prompt',
          }}
        />
        <FormField
          control={form.control}
          name="model"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Model</FormLabel>
              <FormControl>
                <div className="flex items-center space-x-2">
                  {videoModelsOptions.map(({ key, label }) => (
                    <Badge
                      key={key}
                      variant={field.value === key ? 'default' : 'outline'}
                      className="rounded-full font-normal cursor-pointer"
                      onClick={() => {
                        field.onChange(key);
                        const model = key;
                        const orientation = form.getValues('orientation');
                        const duration = form.getValues('duration');

                        // Handle duration constraints
                        switch (model) {
                          case VideoModelNames.ray16:
                            // Ray 1.6 only supports 5s
                            form.setValue('duration', 5);
                            break;
                          case VideoModelNames.ray2:
                            // Ray 2 supports 5s and 9s
                            if (duration !== 5 && duration !== 9) {
                              form.setValue('duration', 5);
                            }
                            break;
                          case VideoModelNames.gen3turbo:
                          case VideoModelNames.gen3:
                            // Gen3 models don't support 9s
                            if (duration === 9) {
                              form.setValue('duration', 5);
                            }
                            break;
                        }

                        // Handle orientation constraints
                        if (model === VideoModelNames.gen3) {
                          // Gen3 only supports landscape
                          form.setValue('orientation', 'landscape');
                        } else if (model === VideoModelNames.gen3turbo) {
                          // Gen3 Turbo only supports landscape and portrait
                          if (
                            orientation !== 'landscape' &&
                            orientation !== 'portrait'
                          ) {
                            form.setValue('orientation', 'landscape');
                          }
                        } else if (model !== VideoModelNames.ray2) {
                          // Default to landscape for all models except Ray 2
                          form.setValue('orientation', 'landscape');
                        }
                      }}
                    >
                      {label}
                    </Badge>
                  ))}
                </div>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className={'flex gap-4'}>
          {(form.watch('model') === 'ray-1-6' ||
            form.watch('model') === 'ray-2') && (
            <FormSwitch
              {...{
                form,
                name: 'isLoop',
                label: 'Loop',
              }}
            />
          )}
          <FormField
            control={form.control}
            name="orientation"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Aspect ratio</FormLabel>
                <FormControl>
                  <div className={'flex flex-nowrap gap-1'}>
                    {sizes.map(({ key, label }) => (
                      <Badge
                        key={key}
                        variant={field.value === key ? 'default' : 'outline'}
                        className="rounded-full font-normal cursor-pointer"
                        onClick={() => field.onChange(key)}
                      >
                        <div
                          className={cn(
                            'border h-2',
                            field.value === key && 'mr-1'
                          )}
                          style={{
                            aspectRatio: label.replace(':', '/'),
                          }}
                        />
                        <span>{field.value === key && label}</span>
                      </Badge>
                    ))}
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormBadge
            {...{
              form,
              name: 'duration',
              label: 'Duration',
              options: [
                {
                  key: 5,
                  label: '5s',
                },
                ...(model === VideoModelNames.ray16
                  ? []
                  : model === VideoModelNames.ray2
                  ? [
                      {
                        key: 9,
                        label: '9s',
                      },
                    ]
                  : [
                      {
                        key: 10,
                        label: '10s',
                      },
                    ]),
              ],
            }}
          />
        </div>
        <div className={'flex gap-4'}>
          <FormImage
            {...{
              form,
              name: 'image',
              label: 'Start frame',
              size: 'small',
            }}
          />
          {form.watch('image') && form.watch('model') !== 'gen3' && (
            <FormImage
              {...{
                form,
                name: 'imageEnd',
                label: 'End frame',
                size: 'small',
              }}
            />
          )}
        </div>

        <Button type="submit" disabled={isLoading} className={'float-end'}>
          {isLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
          Continue
        </Button>
      </form>
    </Form>
  );
};

GenerateVideo.defaultProps = defaultProps;

export default GenerateVideo;
