import { ref, uploadBytes } from 'firebase/storage';
import _ from 'lodash';
import sanitize from 'sanitize-filename';

import {
  BUCKET_URL,
  BUCKET_URL_PREFIX,
  projectId,
  storage,
} from './api/firebase';
import { nanoid } from 'nanoid';
import path from 'path';

export function generateName(file: File, fileId: string) {
  const fileExt = file.name.split('.').pop();
  return `${fileId}.${fileExt}`;
}

export const toDataUrl = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export const dataUrlToBlob = async (dataUrl: string, fileType: string) => {
  const binaryString = Buffer.from(dataUrl.split(',')[1], 'base64').toString(
    'binary'
  );
  const binaryData = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    binaryData[i] = binaryString.charCodeAt(i);
  }
  return new Blob([binaryData], { type: fileType });
};

export const getFormattedDate = (
  date: Date | string | number,
  displayLocale: string,
  withTime = true
) =>
  new Intl.DateTimeFormat(displayLocale, {
    dateStyle: 'medium',
    ...(withTime && { timeStyle: 'medium' }),
  }).format(new Date(date));

export enum ThumbnailSize {
  '_144' = '_144x144',
  '_377' = '_377x377',
  '_610' = '_610x610',
  '_1597' = '_1597x1597',
}

export const thumbUrl = (url: string, size: ThumbnailSize): string => {
  try {
    // Adjusted regex pattern to capture the path before the filename and the filename with extension
    // eslint-disable-next-line no-useless-escape
    const pattern = /(.+images%2F)([^\/]+)(\.\w+)(\?.*)?$/;
    const match = url.match(pattern);
    if (!match) throw new Error('No Match');
    const pathBeforeImages = match[1];
    const filename = match[2];
    const extension = match[3];
    const queryParams = match[4] || ''; // Default to empty string if no query parameters
    const newFilename = `${pathBeforeImages}thumbs%2F${filename}${size}${extension}${queryParams}`;
    return newFilename;
  } catch (error) {
    return url;
  }
};

export const createUniqueFilename = (props: {
  index?: string;
  brandPrefix?: string;
  projectPrefix?: string;
  title?: string;
  text: string;
  extension: string;
  suffix?: string;
  characters?: number;
}) => {
  const {
    index = '',
    brandPrefix = '',
    projectPrefix = '',
    title = '',
    text,
    suffix = 'euryka',
    extension,
    characters = 10,
  } = props;
  const titleSanitize = sanitize(title).replace(/[^a-zA-Z0-9]/g, '_');
  const textSanitize = sanitize(text).replace(/[^a-zA-Z0-9]/g, '_');
  const fileName = `${index ? `${index}-` : ''}${
    brandPrefix
      ? `${_.truncate(brandPrefix, { length: characters, omission: '...' })}-`
      : ''
  }${
    projectPrefix
      ? `${_.truncate(projectPrefix, { length: characters, omission: '...' })}-`
      : ''
  }${
    titleSanitize
      ? `${_.truncate(titleSanitize, { length: characters, omission: '...' })}-`
      : ''
  }${
    textSanitize
      ? `${_.truncate(textSanitize, { length: characters, omission: '...' })}-`
      : ''
  }${suffix ? `${suffix}` : ''}.${extension}`;
  return sanitize(fileName);
};

export const uploadFile = async (props: { wsId: string; file?: File }) => {
  const { wsId, file } = props;
  if (!file) return '';
  try {
    if (process.env.IS_DEBUG)
      console.log('file -- uploadFile -- props:', props);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const originalFileName = file.name;
    const fileName = generateName(file, nanoid());
    const isImage = file.type.includes('image');
    const storageRef = ref(
      storage,
      `${BUCKET_URL}/users/${wsId}/${isImage ? 'images' : 'files'}/${fileName}`
    );
    await uploadBytes(storageRef, file, {
      contentType: file.type,
      cacheControl: 'public, max-age=31536000, immutable',
    });

    return `${BUCKET_URL_PREFIX}users%2F${wsId}%2F${
      isImage ? 'images' : 'files'
    }%2F${fileName}?alt=media`;
  } catch (error) {
    console.log('file -- uploadFile -- error:', error);
  }
};

export const getFsPathFromUrl = (url: string): string => {
  try {
    const parsedUrl = new URL(url);
    const pathname = parsedUrl.pathname;

    if (pathname.includes('/o/')) {
      const pathWithQuery = pathname.split('/o/')[1];
      return decodeURIComponent(pathWithQuery.split('?')[0]);
    } else {
      console.warn('URL is not Firebase Storage URL', url);
      return pathname.split('?')[0];
    }
  } catch (error) {
    console.error('getFsPathFromUrl -- error:', error);
    return url;
  }
};

export const getGcsFileUrl = (filePath: string) => {
  return `https://storage.googleapis.com/${projectId}.appspot.com/${filePath}`;
};

export const getImageDimensions = (file: File) => {
  return new Promise<{ width: number; height: number }>((resolve, reject) => {
    const img = new Image();
    // Create a URL for the file
    const url = URL.createObjectURL(file);
    // Load the image and resolve the promise with its dimensions
    img.onload = () => {
      // Revoke the object URL to free up memory
      URL.revokeObjectURL(url);
      resolve({ width: img.width, height: img.height });
    };
    img.onerror = () => {
      // Revoke the object URL in case of an error
      URL.revokeObjectURL(url);
      reject(new Error('Error loading image'));
    };
    // Set the image source to the file URL
    img.src = url;
  });
};

export const getFileNameFromUrl = url => {
  const urlObj = new URL(url);
  const pathname = decodeURIComponent(urlObj.pathname);

  const filename = path.basename(pathname);
  const extension = path.extname(pathname);

  return { filename, extension };
};

export const getContentType = async (url: string) => {
  try {
    const response = await fetch(url, { method: 'HEAD' });
    return response.headers.get('content-type');
  } catch (error) {
    console.error('Error fetching content type:', error);
    return null;
  }
};

export const getWsIdFromPath = (path: string) => {
  const pattern = /users\/([a-zA-Z0-9]+)\/brands/;
  const match = path.match(pattern);
  return match ? match[1] : null;
};

export const getAllIdsFromPath = (path: string) => {
  // Match any segment that comes after '/' and contains alphanumeric characters
  const pattern = /\/([a-zA-Z0-9]+)/g;
  const matches = [...path.matchAll(pattern)];

  // Filter out non-ID segments
  const nonIdSegments = [
    'users',
    'posts',
    'brands',
    'imaginations',
    'iterations',
    'documents',
    'threads',
    'voiceovers',
    'characters',
  ];
  const ids = matches
    .map(match => match[1])
    .filter(segment => !nonIdSegments.includes(segment));

  // Create base return object with wsId
  const result: Record<string, string | null> = {
    wsId: ids[0] || null,
  };

  // Add other IDs based on path type
  if (path.includes('/imaginations/')) {
    result.iId = ids[1] || null;
    result.iiId = ids[2] || null;
  }
  if (path.includes('/posts/')) {
    result.iId = ids[1] || null;
    result.iiId = ids[2] || null;
  }
  if (path.includes('/documents/')) {
    result.dId = ids[1] || null;
  }
  if (path.includes('/threads/')) {
    result.tId = ids[1] || null;
  }
  if (path.includes('/voiceovers/')) {
    result.vId = ids[1] || null;
  }
  if (path.includes('/characters/')) {
    result.cId = ids[1] || null;
  }
  if (path.includes('/brands/')) {
    result.bId = ids[1] || null;
  }

  return result;
};
