import { zodResolver } from '@hookform/resolvers/zod';
import { Loader2 } from 'lucide-react';
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as z from 'zod';

import { Badge } from 'components/ui/badge';
import { Button } from 'components/ui/button';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'components/ui/form';
import { Slider } from 'components/ui/slider';
import { useGlobalStore } from 'store';
import { useEffectOnce } from 'react-use';
import { modelVersions } from 'utils/config';
import { FormSwitch, FormTextarea } from '../ui/form.utils';
import useImaginations from '@/hooks/useImaginations';
import _ from 'lodash';

export interface GenerateAudioProps {
  adornment?: React.ReactNode;
}

const defaultProps: Partial<GenerateAudioProps> = {};

const formSchema = z.object({
  prompt: z.string().nonempty({
    message: 'Prompt is required',
  }),
  enhancePrompt: z.boolean().optional(),
  modelVersion: z.string(),
  duration: z.number(),
});

const formDefaultValues: z.infer<typeof formSchema> = {
  prompt: '',
  enhancePrompt: true,
  modelVersion: 'stereo-melody-large',
  duration: 8,
};

const GenerateAudio: React.FC<GenerateAudioProps> = ({ adornment }) => {
  const [isLoading, setLoading] = useState(false);
  const { setGenerateOpen, generateProps, setGenerateProps } = useGlobalStore();
  const { onGenerateAudio } = useImaginations();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: formDefaultValues,
  });

  const formReset = useCallback(() => {
    form.reset(formDefaultValues);
    setGenerateProps(null);
  }, [form, setGenerateProps]);

  const onSubmit = useCallback(
    async (values: z.infer<typeof formSchema>) => {
      if (process.env.IS_DEBUG)
        console.log('LayoutGenerate -- onSubmit -- values:', values);
      setLoading(true);
      try {
        await onGenerateAudio(values);
        setGenerateOpen(false);
        formReset();
      } finally {
        setLoading(false);
      }
    },
    [formReset, onGenerateAudio, setGenerateOpen]
  );

  useEffectOnce(() => {
    form.reset({
      ...formDefaultValues,
      ..._.pick(generateProps, [
        'prompt',
        'modelVersion',
        'duration',
        'enhancePrompt',
      ]),
    });
  });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FormTextarea
          {...{
            form,
            name: 'prompt',
            label: 'Prompt',
            placeholder: 'Type a prompt',
            adornment,
            onChange: (value: string) =>
              setGenerateProps({
                ...generateProps,
                prompt: value,
              }),
          }}
        />
        <FormSwitch
          {...{
            form,
            name: 'enhancePrompt',
            label: 'Enhance prompt',
          }}
        />
        <FormField
          control={form.control}
          name="modelVersion"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Model</FormLabel>
              <FormControl>
                <div className="flex items-center gap-1 flex-wrap">
                  {modelVersions.map(({ key, label }) => (
                    <Badge
                      key={key}
                      variant={field.value === key ? 'default' : 'outline'}
                      className="rounded-full font-normal cursor-pointer whitespace-nowrap"
                      onClick={() => {
                        field.onChange(key);
                      }}
                    >
                      {label}
                    </Badge>
                  ))}
                </div>
              </FormControl>
              <FormDescription>
                {
                  modelVersions.find(
                    modelVersion => modelVersion.key === field.value
                  ).description
                }
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="duration"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Duration - {form.watch('duration')} seconds</FormLabel>
              <FormControl>
                <Slider
                  defaultValue={[form.watch('duration')]}
                  min={4}
                  max={60}
                  step={1}
                  onValueChange={([value]) => field.onChange(value)}
                />
              </FormControl>
              <FormDescription></FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <Button type="submit" disabled={isLoading} className={'float-end'}>
          {isLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
          Continue
        </Button>
      </form>
    </Form>
  );
};

GenerateAudio.defaultProps = defaultProps;

export default GenerateAudio;
