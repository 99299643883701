import Image from 'next/image';
import React from 'react';

import { AspectRatio } from 'components/ui/aspect-ratio';
import { Button } from 'components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog';
import useCommon from 'hooks/useCommon';
import useGlobalStore from 'store/global';
import { ROUTES } from 'utils/routes';
import { ProductType } from '@/utils/api/stripe';

export interface LayoutTrialDialogProps {}

const defaultProps: Partial<LayoutTrialDialogProps> = {};

const LayoutTrialDialog: React.FC<LayoutTrialDialogProps> = () => {
  const { routeTo } = useCommon();
  const { stripeRole, isTrialOpen, setTrialOpen } = useGlobalStore();
  if (stripeRole !== ProductType.Free) return <></>;
  return (
    <Dialog open={isTrialOpen} onOpenChange={setTrialOpen}>
      <DialogContent className={'max-w-2xl'}>
        <DialogHeader>
          <DialogTitle
            className={'scroll-m-20 text-2xl font-semibold tracking-tight'}
          >
            Start your Euryka AI journey
          </DialogTitle>
        </DialogHeader>
        <AspectRatio ratio={680 / 160} className={''}>
          <Image
            src={'/images/ek-free-trial-image.webp'}
            alt="Start your free trial"
            fill
            className={'rounded-md object-cover'}
          />
        </AspectRatio>
        <p className={'pb-2'}>
          Welcome! We are so glad you have joined Euryka. Let&apos;s get you
          started on your Al journey. First steps is to explore which plan you
          want to use.
        </p>

        <DialogFooter className="sm:justify-start">
          <Button
            onClick={() => {
              routeTo({ route: ROUTES.ACCOUNT_BILLING });
              setTrialOpen(false);
            }}
          >
            Continue
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

LayoutTrialDialog.defaultProps = defaultProps;

export default LayoutTrialDialog;
