import { useEffect, useMemo } from 'react';
import useDocumentsStore from 'store/documents';
import useCommon from './useCommon';
import { ROUTES } from '@/utils/routes';

const useDocument = (props: {
  wsId: string;
  uid: string;
  uemail: string;
  dId: string;
}) => {
  const { router } = useCommon();
  const { wsId, uid, uemail, dId } = props;

  const { documents, getDocument, createDocument, updateDocument } =
    useDocumentsStore();

  useEffect(() => {
    if (dId) getDocument(wsId, dId);
  }, [wsId, dId, getDocument]);

  const document = documents.find(d => d.id === dId);

  if (
    document &&
    document?.createdBy !== uid &&
    !document?.readers?.includes(uemail) &&
    !document?.editors?.includes(uemail) &&
    !document?.owners?.includes(uemail)
  ) {
    router.push(ROUTES.DOCS.replace('[wsId]', wsId));
  }

  const privileges = useMemo(() => {
    const p = {
      canView: false,
      canEdit: false,
      canManage: false,
    };

    if (document?.createdBy === uid) {
      return {
        canView: true,
        canEdit: true,
        canManage: true,
      };
    }

    if (document?.readers?.includes(uemail)) {
      return {
        canView: true,
      };
    }

    if (document?.editors?.includes(uemail)) {
      return {
        canView: true,
        canEdit: true,
      };
    }

    if (document?.owners?.includes(uemail)) {
      return {
        canView: true,
        canEdit: true,
        canManage: true,
      };
    }

    return p;
  }, [document, uid, uemail]);

  return {
    loading: !document,
    document,
    brandId: document?.brandId,
    projectId: document?.projectId,
    updateDocument,
    createDocument,
    title: document?.title || document?.titleGenerated || 'Untitled Document',
    hasPendingWrites: document?.hasPendingWrites || false,
    readers: document?.readers || [],
    editors: document?.editors || [],
    owners: document?.owners || [],
    privileges,
    content: document?.content || '',
    contentHtml: document?.contentHtml || '',
    createdBy: document?.createdBy || '',
    regeneratedBy: document?.regeneratedBy || '',
    sparkId: document?.sparkId || '',
    willHavePendingWrites: document?.willHavePendingWrites || false,
    contentJson: document?.contentJson,
  };
};

export default useDocument;
