import _ from 'lodash';
import { useMemo } from 'react';
import { useEffectOnce } from 'react-use';

import useProjectsStore from 'store/projects';
import useWs from './useWs';
import useUser from './useUser';

const useProjects = (props: { wsId: string; uid: string; bId?: string }) => {
  const { wsId, uid } = props;
  const { canEdit } = useWs({ wsId, uid });
  const { email } = useUser({ uid });
  const { projects, getProjects } = useProjectsStore();

  const filteredProjects = useMemo(() => {
    const nonArchivedProjects = projects.filter(project => !project.isArchive);
    if (props.bId) {
      return nonArchivedProjects.filter(
        project => project?.brands?.includes(props.bId)
      );
    }
    if (!canEdit) {
      return nonArchivedProjects.filter(project => {
        const {
          members = [],
          supervisors = [],
          leaders = [],
          createdBy,
        } = project;
        return (
          members.includes(email) ||
          supervisors.includes(email) ||
          leaders.includes(email) ||
          createdBy === uid
        );
      });
    }
    return nonArchivedProjects;
  }, [projects, props.bId, canEdit, uid, email]);

  useEffectOnce(() => {
    getProjects(wsId, uid, true);
  });

  return {
    projects: _.sortBy(filteredProjects, ['name']),
    noOfProjects: filteredProjects.length,
  };
};

export default useProjects;
