import axios, { AxiosInstance } from 'axios';
import getNextConfig from 'next/config';

const env = getNextConfig().publicRuntimeConfig;
const baseURL = `${env.API_URL}/api`;

class API {
  client: AxiosInstance;

  constructor() {
    this.reset();
  }

  reset() {
    this.client = axios.create({ baseURL });
    this.client.defaults.withCredentials = false;
    this.client.defaults.headers.common = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };
  }

  async addVoice(props: { wsId: string; uid: string; vId: string }) {
    const response = await this.client.post('/elevenLabs/add', props, {
      headers: {
        Authorization: env.API_ROUTES_PUBLIC_KEY,
      },
    });
    return response.data;
  }

  async editVoice(props: { wsId: string; uid: string; vId: string }) {
    const response = await this.client.post('/elevenLabs/edit', props, {
      headers: {
        Authorization: env.API_ROUTES_PUBLIC_KEY,
      },
    });
    return response.data;
  }

  async generateVoiceoverVoice(props: { text: string; voiceId: string }) {
    const response = await this.client.post('/elevenLabs/tts', props, {
      headers: {
        Authorization: env.API_ROUTES_PUBLIC_KEY,
      },
    });
    return response.data;
  }

  async generateVoiceoverVoiceSts({ voiceId, blobUrl }) {
    const formData = new FormData();
    formData.append('voiceId', voiceId);
    formData.append('blobUrl', blobUrl);

    const response = await this.client.post('/elevenLabs/sts', formData, {
      headers: {
        Authorization: env.API_ROUTES_PUBLIC_KEY,
      },
    });

    return response.data;
  }

  async downloadArchive({
    files,
    archiveName,
  }: {
    files: { name: string; url: string; contentType: string }[];
    archiveName?: string;
  }) {
    const response = await this.client.post(
      '/downloadArchive',
      {
        files,
      },
      {
        responseType: 'blob',
      }
    );
    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: 'application/zip' })
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', archiveName || 'archive.zip');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
    return response.data;
  }

  async downloadArchiveFile({
    files,
    archiveName,
  }: {
    files: { name: string; url: string; contentType: string }[];
    archiveName?: string;
  }) {
    const response = await this.client.post(
      '/downloadArchiveFile',
      {
        files,
        archiveName,
      },
      {
        responseType: 'blob',
      }
    );
    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: 'application/zip' })
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', archiveName || 'archive.zip');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
    return response.data;
  }

  async downloadFile({ file }: { file: { name: string; url: string } }) {
    const response = await this.client.post(
      '/downloadFile',
      {
        file,
      },
      {
        responseType: 'blob',
      }
    );
    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: response.headers['Content-Type'] })
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', file.name);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
    return response.data;
  }

  // Teams ---
  async resendInviteToWs(props) {
    const response = await this.client.post('/resend/inviteToWs', props);
    return response.data;
  }

  async autofillValues(props: { url: string }) {
    const response = await this.client.post('/brands/autofill', props);
    return response.data;
  }

  async onLookup(props: {
    key: string;
    prompt: string;
    type: string;
    wsId?: string;
    brands?: string[];
    name?: string;
  }) {
    console.log('api -- onLookup -- props:', props);
    const response = await this.client.post(
      `/${props.type}s/${props.key}`,
      props
    );
    return response.data;
  }

  // Threads ---
  async generateEmbedding(props: { wsId: string; tId: string; mId: string }) {
    const response = await this.client.post('/thread/message/embedding', props);
    return response.data;
  }
  async transcribeAudio(props: { wsId: string; tId: string; mId: string }) {
    const response = await this.client.post(
      '/thread/message/transcribe',
      props
    );
    return response.data;
  }
  async transcribeAudioIntent(props: {
    wsId: string;
    tId: string;
    uid: string;
    mId: string;
    iId: string;
  }) {
    const response = await this.client.post(
      '/thread/message/transcribe-intent',
      props
    );
    return response.data;
  }
  async createMessage(props) {
    const response = await this.client.post('/thread/message/create', props);
    return response.data;
  }
  async editMessage(props) {
    const response = await this.client.post('/thread/message/edit', props);
    return response.data;
  }
  async regenerateMessage(props) {
    const response = await this.client.post(
      '/thread/message/regenerate',
      props
    );
    return response.data;
  }

  // Auth ---
  async sendOTP(props) {
    const response = await this.client.post('/auth/otp', props);
    return response.data;
  }

  // Dashboard ---
  async quickStart(props) {
    const response = await this.client.post('/dashboard/quickstart', props);
    return response.data;
  }

  // Docs ---
  async getAiToken() {
    const response = await this.client.post('/ai', {});
    return response.data;
  }
  async getExportToken() {
    const response = await this.client.post('/export', {});
    return response.data;
  }
  async getDocumentExists(props: { docId: string }) {
    const response = await this.client.post('/document', props);
    return response.data;
  }
  async createDocument(props: { wsId: string; dId: string }) {
    const response = await this.client.post('/document/content/create', props);
    return response.data;
  }
  async regenerateDocument(props: { wsId: string; dId: string }) {
    const response = await this.client.post(
      '/document/content/regenerate',
      props
    );
    return response.data;
  }
  async generateSuggestion(props: {
    wsId: string;
    contextId: string;
    brandId?: string;
    projectId?: string;
    sparkId?: string;
  }) {
    const response = await this.client.post('/document/suggestion', props);
    return response.data;
  }
  async aiImage(props) {
    const response = await this.client.post('/document/image/ai-image', props);
    return response.data;
  }
  async aiWriter(props) {
    const response = await this.client.post(
      '/document/content/ai-writer',
      props
    );
    return response.data;
  }

  // Stripe ---
  async updateSubscriptionQuantity(props: {
    wsId: string;
    removeSeat?: boolean;
  }) {
    const response = await this.client.post('/stripe/update-quantity', props);
    return response.data;
  }
  async updateSubscriptionPlan(props: { wsId: string; priceId: string }) {
    const response = await this.client.post('/stripe/update-plan', props);
    return response.data;
  }
}

export default new API();
