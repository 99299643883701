import { zodResolver } from '@hookform/resolvers/zod';
import {
  BrainCircuit,
  Infinity,
  Layers2,
  Loader2,
  Sailboat,
} from 'lucide-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as z from 'zod';

import { Badge } from 'components/ui/badge';
import { Button } from 'components/ui/button';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'components/ui/form';
import { useGlobalStore } from 'store';
import { cn } from 'utils';

import { useSvgIcon } from 'growl/hooks';
import {
  dallE3Styles,
  ideogramStyles,
  ImageModelNames,
  orientationsDallE3,
  orientationsImage,
  recraftStyles,
  sdStyles,
} from '@/utils/config';
import {
  FormImage,
  FormInput,
  FormSlider,
  FormSwitch,
  FormTextarea,
} from '../ui/form.utils';
import { useEffectOnce } from 'react-use';
import useImaginations from '@/hooks/useImaginations';
import _ from 'lodash';

export interface GenerateImageProps {
  adornment?: React.ReactNode;
}

const defaultProps: Partial<GenerateImageProps> = {};

const formSchema = z.object({
  prompt: z.string().nonempty({
    message: 'Prompt is required',
  }),
  negativePrompt: z.string().optional(),
  enhancePrompt: z.boolean().optional(),
  model: z.string(),
  orientation: z.string(),
  style: z.string().optional(),
  image: z.custom<File | undefined>(file => {
    if (file && !(file instanceof File)) {
      throw new Error('Image must be a file');
    }
    return true;
  }),
  imageWeight: z.number(),
});

const formDefaultValues: z.infer<typeof formSchema> = {
  prompt: '',
  negativePrompt: '',
  enhancePrompt: true,
  model: 'midJourney',
  style: 'general',
  orientation: 'square',
  image: null,
  imageWeight: 50,
};

const GenerateImage: React.FC<GenerateImageProps> = ({ adornment }) => {
  const { generateProps, setGenerateOpen, setGenerateProps } = useGlobalStore();
  const { onGenerateImage } = useImaginations();
  const OpenaiLogo = useSvgIcon('openai');
  const RecraftLogo = useSvgIcon('recraft');

  const [isLoading, setLoading] = useState(false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: formDefaultValues,
  });

  const formReset = useCallback(() => {
    form.reset(formDefaultValues);
    setGenerateProps(null);
  }, [form, setGenerateProps]);

  const onSubmit = useCallback(
    async (values: z.infer<typeof formSchema>) => {
      if (process.env.IS_DEBUG)
        console.log('GenerateImage -- onSubmit -- values:', values);

      try {
        setLoading(true);
        await onGenerateImage(values);
        setGenerateOpen(false);
        formReset();
      } finally {
        setLoading(false);
      }
    },
    [formReset, onGenerateImage, setGenerateOpen]
  );

  const model = form.watch('model');
  const sizes = useMemo(() => {
    if (model === ImageModelNames.dallE3) return orientationsDallE3;
    return orientationsImage;
  }, [model]);

  const styles = useMemo(() => {
    if (model === ImageModelNames.midJourney) return [];
    if (model === ImageModelNames.sd) return sdStyles;
    if (model === ImageModelNames.dallE3) return dallE3Styles;
    if (model === ImageModelNames.ideogram) return ideogramStyles;
    if (model === ImageModelNames.recraft) return recraftStyles;
    return [];
  }, [model]);

  const canRefImage = useMemo(() => {
    return (
      model === ImageModelNames.midJourney ||
      model === ImageModelNames.flux ||
      model === ImageModelNames.fluxUltra ||
      model === ImageModelNames.ideogram
    );
  }, [model]);

  useEffectOnce(() => {
    form.reset({
      ...formDefaultValues,
      ..._.pick(generateProps, [
        'prompt',
        'negativePrompt',
        'enhancePrompt',
        'model',
        'style',
        'orientation',
      ]),
    });
  });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FormTextarea
          {...{
            form,
            name: 'prompt',
            label: 'Prompt',
            placeholder: 'Type a prompt',
            adornment,
            onChange: (value: string) => {
              setGenerateProps({
                ...generateProps,
                prompt: value,
              });
            },
          }}
        />
        <FormInput
          {...{
            form,
            name: 'negativePrompt',
            label: 'Negative prompt',
            placeholder: "Type what you don't want to see in the image...",
          }}
        />
        <div className={'flex gap-x-4 flex-wrap'}>
          <FormSwitch
            {...{
              form,
              name: 'enhancePrompt',
              label: 'Enhance prompt',
            }}
          />
        </div>
        <div className={'flex gap-x-4 flex-wrap'}>
          <FormField
            control={form.control}
            name="model"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Model</FormLabel>
                <FormControl>
                  <div className="flex items-center space-x-2">
                    {[
                      {
                        key: ImageModelNames.midJourney,
                        IconComponent: Sailboat,
                        label: 'Mid journey',
                      },
                      {
                        key: ImageModelNames.sd,
                        IconComponent: Layers2,
                        label: 'Stable diffusion',
                      },
                      {
                        key: ImageModelNames.flux,
                        IconComponent: Infinity,
                        label: 'Flux Pro',
                      },
                      {
                        key: ImageModelNames.fluxUltra,
                        IconComponent: Infinity,
                        iconLabel: '+',
                        label: 'Flux Ultra',
                      },
                      {
                        key: ImageModelNames.dallE3,
                        IconComponent: OpenaiLogo.SvgIcon,
                        label: 'Dall-E 3',
                      },
                      {
                        key: ImageModelNames.ideogram,
                        IconComponent: BrainCircuit,
                        label: 'Ideogram',
                      },
                      {
                        key: ImageModelNames.recraft,
                        label: 'Recraft',
                        IconComponent: RecraftLogo.SvgIcon,
                      },
                    ].map(({ key, label, IconComponent, iconLabel }) => (
                      <Badge
                        key={key}
                        variant={field.value === key ? 'default' : 'outline'}
                        className="rounded-full font-normal cursor-pointer"
                        onClick={() => {
                          field.onChange(key);
                          if (key === ImageModelNames.midJourney)
                            form.setValue('style', '');
                          if (key === ImageModelNames.sd)
                            form.setValue(
                              'style',
                              sdStyles.find(s => s.label === 'Dynamic')?.key
                            );
                          if (key === ImageModelNames.dallE3)
                            form.setValue('style', 'vivid');
                          if (key === ImageModelNames.recraft)
                            form.setValue('style', 'any');
                          if (key === ImageModelNames.ideogram)
                            form.setValue('style', 'AUTO');
                        }}
                      >
                        <IconComponent
                          className={cn(
                            'h-4 w-4',
                            field.value === key && !iconLabel && 'mr-1'
                          )}
                        />
                        {iconLabel && (
                          <span
                            className={cn(
                              'text-xs ml-0.5',
                              field.value === key && 'mr-1'
                            )}
                          >
                            {iconLabel}
                          </span>
                        )}
                        {field.value === key && label}
                      </Badge>
                    ))}
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="orientation"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Aspect ratio</FormLabel>
                <FormControl>
                  <div className={'flex flex-nowrap gap-1'}>
                    {sizes.map(({ key, label }) => (
                      <Badge
                        key={key}
                        variant={field.value === key ? 'default' : 'outline'}
                        className="rounded-full font-normal cursor-pointer"
                        onClick={() => field.onChange(key)}
                      >
                        <div
                          className={cn(
                            'border h-2',
                            field.value === key && 'mr-1'
                          )}
                          style={{
                            aspectRatio: label.replace(':', '/'),
                          }}
                        />
                        <span>{field.value === key && label}</span>
                      </Badge>
                    ))}
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        {!!styles.length && (
          <FormField
            control={form.control}
            name="style"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Style</FormLabel>
                <FormControl>
                  <div className={'flex flex-wrap gap-1'}>
                    {styles.filter(Boolean).map(({ key, label }) => (
                      <Badge
                        key={key}
                        variant={field.value === key ? 'default' : 'outline'}
                        className="rounded-full font-normal cursor-pointer"
                        onClick={() => field.onChange(key)}
                      >
                        {label}
                      </Badge>
                    ))}
                  </div>
                </FormControl>
                <FormDescription></FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
        )}

        {canRefImage && (
          <>
            <FormImage
              {...{
                form,
                name: 'image',
                label: 'Image reference',
                size: 'small',
              }}
            />
            {form.watch('image') && (
              <FormSlider
                {...{
                  form,
                  name: 'imageWeight',
                  label: 'Image weight',
                  min: 0,
                  max: 100,
                  step: 1,
                }}
              />
            )}
          </>
        )}

        <Button type="submit" disabled={isLoading} className={'float-end'}>
          {isLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
          Continue
        </Button>
      </form>
    </Form>
  );
};

GenerateImage.defaultProps = defaultProps;

export default GenerateImage;
