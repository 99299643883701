import useCommon from '@/hooks/useCommon';
import useDocument from '@/hooks/useDocument';
import useThread from '@/hooks/useThread';
import useUser from '@/hooks/useUser';
import useGlobalStore from '@/store/global';
import { Timestamp } from 'firebase/firestore';
import { UserAvatar } from '../WsAvatar';
import { TOAST_DEFAULTS } from '@/utils/config';
import { toast } from 'sonner';
import { ROUTES } from '@/utils/routes';
import { Button } from '../ui/button';
import { ArrowRight } from 'lucide-react';

const Notification = (props: {
  uid: string;
  path: string;
  createdBy: string;
  isSeen: boolean;
  isRead: boolean;
  createdAt: Timestamp;
}) => {
  const { router } = useCommon();
  const { setNotificationsOpen } = useGlobalStore();

  const { uid, path, createdBy } = props;

  const segments = path.split('/');
  const wsId = segments[1];
  const docId = segments[3];
  const isThread = path.includes('threads');
  const isDocument = path.includes('documents');

  const { email } = useUser({ uid });
  const { name } = useUser({ uid: createdBy });
  const { title, hasAccess } = useThread({ wsId, threadId: docId, uid, email });
  const { title: documentTitle, privileges } = useDocument({
    wsId,
    uid,
    uemail: email,
    dId: docId,
  });

  if (!isThread && !isDocument) return null;

  return (
    <div
      className={
        'flex items-start gap-4 bg-card shadow-card p-4 pb-3.5 rounded-lg'
      }
    >
      <UserAvatar {...{ uid: createdBy, size: 'base' }} />
      <div>
        <p className="text-sm font-medium line-clamp-1">{`${
          name ? `${name} added you` : 'You have been added'
        } to a ${isThread ? 'thread' : isDocument ? 'document' : ''}.`}</p>
        <p className="text-sm text-muted-foreground line-clamp-1">
          {isThread ? 'Thread' : isDocument ? 'Document' : 'Imagination'}:
          &quot;{title || documentTitle}
          &quot;
        </p>
        <Button
          variant={'link'}
          size={'sm'}
          className={'p-0 h-[unset]'}
          onClick={() => {
            setNotificationsOpen(false);
            if (isDocument && !privileges.canView) {
              toast.error(
                'You are not allowed to access this document. Your access might have been revoked.',
                TOAST_DEFAULTS
              );
              return;
            }
            if (isThread && !hasAccess) {
              toast.error(
                'You are not allowed to access this thread. Your access might have been revoked.',
                TOAST_DEFAULTS
              );
              return;
            }
            router.push(
              ROUTES[isThread ? 'THREAD' : isDocument ? 'DOC' : '']
                .replace('[wsId]', wsId)
                .replace('[dId]', docId)
                .replace('[tId]', docId)
            );
          }}
        >
          View {isThread ? 'thread' : isDocument ? 'document' : ''}
          <ArrowRight className={'ml-1 w-4 h-4'} />
        </Button>
      </div>
    </div>
  );
};

export default Notification;
