import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  DocumentData,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  QueryDocumentSnapshot,
  serverTimestamp,
  startAfter,
  updateDoc,
} from 'firebase/firestore';
import create from 'zustand';

import { toast } from 'components/ui/use-toast';
import { db } from 'utils/api/firebase';
import { getWsIdFromPath, uploadFile } from 'utils/file';

import { Brand } from './brands.types';
import { TODO } from './global.types';

export interface BrandsStoreType {
  initialLoading: boolean;
  setInitialLoading: (initialLoading: boolean) => void;
  brands: Brand[];
  syncBrand: (brand: Brand) => void;

  lastVisible: QueryDocumentSnapshot<DocumentData, DocumentData>;
  setLastVisible: (
    lastVisible: QueryDocumentSnapshot<DocumentData, DocumentData>
  ) => void;

  hasMore: boolean;
  setHasMore: (hasMore: boolean) => void;

  limitCount: number;

  getBrands: (wsId: string, uid: string, all?: boolean) => void;

  getBrand: (wsId: string, brandId: string) => TODO;

  createBrand: (wsId: string, data: TODO, uid?: string) => void;

  updateBrand: (docRef: TODO, data: TODO, uid?: string) => void;
}

const useBrandsStore = create<BrandsStoreType>((set, get) => ({
  initialLoading: true,
  setInitialLoading: initialLoading => {
    set(() => ({ initialLoading }));
  },

  brands: [],
  syncBrand: brand => {
    set(({ brands: brands }) => {
      const brandIndex = brands.findIndex(b => b.id === brand.id);
      if (brandIndex === -1) return { brands: [...brands, brand] };
      const updatedBrands = brands.map((b, index) =>
        index === brandIndex ? { ...b, ...brand } : b
      );
      return {
        brands: updatedBrands,
      };
    });
  },
  lastVisible: null,
  setLastVisible: lastVisible => {
    set(() => ({ lastVisible }));
  },

  hasMore: true,
  setHasMore: hasMore => {
    set(() => ({ hasMore }));
  },

  limitCount: 25,

  getBrands: async (wsId, uid, all = false) => {
    if (!wsId || !uid) return;
    const {
      limitCount,
      lastVisible,
      syncBrand,
      hasMore,
      setHasMore,
      setLastVisible,
      getBrands,
      setInitialLoading,
    } = get();

    if (!hasMore) return;

    // init q
    let q = query(collection(db, 'users', wsId, 'brands'));
    // add order desc and limit
    q = query(
      q,
      orderBy('pinned', 'desc'),
      orderBy('createdAt', 'desc'),
      limit(limitCount)
    );
    if (lastVisible) q = query(q, startAfter(lastVisible));
    const documentSnapshots = await getDocs(q);
    setInitialLoading(false);
    documentSnapshots.forEach(doc => {
      syncBrand({ id: doc.id, ref: doc.ref, ...doc.data() });
    });
    if (documentSnapshots.empty || documentSnapshots.size < limitCount)
      setHasMore(false);
    if (documentSnapshots.size) {
      setLastVisible(documentSnapshots.docs[documentSnapshots.size - 1]);
      if (all) getBrands(wsId, uid, all);
    }
  },

  getBrand: async (wsId, brandId) => {
    if (!wsId || !brandId) return;
    const { brands, syncBrand } = get();
    const syncedBrand = brands.find(p => p.id === brandId);
    if (syncedBrand) return syncedBrand;
    const docRef = doc(db, 'users', wsId, 'brands', brandId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const brand = { id: docSnap.id, ref: docSnap.ref, ...docSnap.data() };
      syncBrand(brand);
      return brand;
    }
  },

  createBrand: async (wsId, data, uid) => {
    try {
      if (!wsId) throw new Error('Workspace ID is required.');
      const { logo, ...restProps } = data;
      const logoUrl = await uploadFile({ wsId, file: logo });
      await addDoc(collection(db, 'users', wsId, 'brands'), {
        ...restProps,
        ...(logoUrl && { logoUrl }),
        pinned: [],
        ...(uid && { createdBy: uid }),
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      });
    } catch (error) {
      console.log('useBrandsStore -- createBrand -- error:', error);
      toast({
        title: 'Something went wrong. Please try again.',
      });
    }
  },

  updateBrand: async (docRef, data, uid) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { starred, pinned, logo, logoUrl, ...restProps } = data;
    try {
      const wsId = getWsIdFromPath(docRef.path);

      console.log('useBrandsStore -- updateBrand -- data:', data);

      const logoURL = await uploadFile({ wsId, file: logo });
      await updateDoc(docRef, {
        ...restProps,
        ...(data['starred'] !== undefined && uid
          ? {
              starred:
                data['starred'] === 'remove'
                  ? arrayRemove(uid)
                  : arrayUnion(uid),
            }
          : {}),
        ...(pinned !== undefined && uid
          ? {
              pinned:
                data['pinned'] === 'remove'
                  ? arrayRemove(uid)
                  : arrayUnion(uid),
            }
          : {}),
        ...(logoUrl !== undefined && logoURL ? { logoUrl: logoURL } : {}),
        updatedAt: serverTimestamp(),
        ...(uid && { updatedBy: uid }),
      });
    } catch (error) {
      console.log('useBrandsStore -- updateBrand -- error:', error);
      throw error;
    }
  },
}));

export default useBrandsStore;
