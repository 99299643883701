import { TODO } from '@/store/global.types';
import _ from 'lodash';

export const IS_DEBUG = process.env.IS_DEBUG && true;

export const INVALID_REQUEST = { message: 'Bad request', status: 400 };

export const UNAUTHORIZED = { message: 'Unauthorized', status: 401 };

export const UNAUTHORIZED_INVALID_SESSION = {
  message: 'Invalid session',
  status: 403,
};
export const UNAUTHORIZED_INVALID_TOKEN = {
  message: 'Invalid token',
  status: 403,
};
export const UNAUTHORIZED_INVALID_PAYLOAD = {
  message: 'Invalid payload',
  status: 403,
};

export const METHOD_NOT_ALLOWED = {
  message: 'Method not allowed',
  status: 405,
};

export const INTERNAL_SERVER_ERROR = {
  message: 'Internal server error',
  status: 500,
};

export const llmModels = {
  openai: 'openai',
  anthropic: 'anthropic',
  meta: 'meta',
  gemini: 'gemini',
  mistral: 'mistral',
  'deepseek-v3': 'deepseek-v3',
  'openai-gpt-4.5-preview': 'openai-gpt-4.5-preview',

  // with reasoning
  'openai-o1': 'openai-o1',
  'openai-o1-mini': 'openai-o1-mini',
  'openai-o3-mini': 'openai-o3-mini',
  'anthropic-opus': 'anthropic-opus',
  'deepseek-r1': 'deepseek-r1',

  // other
  'openai-mini': 'openai-mini',
  perplexity: 'perplexity',
  'gpt-4o-search-preview': 'gpt-4o-search-preview',
  'gemini-2.0-flash-exp': 'gemini-2.0-flash-exp',
  'gemini-2.5-pro-exp-03-25': 'gemini-2.5-pro-exp-03-25',
  'ek-deep-research': 'ek-deep-research',
} as const;

export const llmModelsNames = {
  [llmModels.openai]: 'OpenAI',
  [llmModels.anthropic]: 'Anthropic',
  [llmModels.meta]: 'Meta',
  [llmModels.gemini]: 'Gemini',
  [llmModels.mistral]: 'Mistral',
  [llmModels['deepseek-v3']]: 'DeepSeek',
  [llmModels['openai-gpt-4.5-preview']]: 'OpenAI (gpt-4.5-preview)',

  // with reasoning
  [llmModels['openai-o1']]: 'OpenAI (o1)',
  [llmModels['openai-o1-mini']]: 'OpenAI (o1 mini)',
  [llmModels['openai-o3-mini']]: 'OpenAI (o3 mini)',
  [llmModels['anthropic-opus']]: 'Anthropic (opus)',
  [llmModels['gemini-2.5-pro-exp-03-25']]: 'Gemini (Gemini 2.5 pro)',
  [llmModels['deepseek-r1']]: 'DeepSeek',

  // other
  [llmModels['openai-mini']]: 'OpenAI (gpt-4o-mini)',
  [llmModels.perplexity]: 'Perplexity (sonar-pro)',
  [llmModels['gpt-4o-search-preview']]: 'OpenAI (gpt-4o-search-preview)',

  // Custom workflow
  [llmModels['ek-deep-research']]: 'Euryka (Deep Research)',

  // multiple modalities
  [llmModels['gemini-2.0-flash-exp']]: 'Gemini (gemini-2.0-flash-exp)',
};

export const sanitizeForFirestore = async (data: TODO): Promise<TODO> => {
  const replacer = (key: string, value: any) => {
    if (value === null || value === undefined || Number.isNaN(value)) {
      return undefined;
    }
    return value;
  };

  try {
    return JSON.parse(JSON.stringify(data, replacer));
  } catch (error) {
    console.error('Sanitization error:', error);
    throw error;
  }
};
