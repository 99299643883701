import { AudioLines, Image, Video } from 'lucide-react';
import React, { useState } from 'react';

import { Badge } from 'components/ui/badge';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog';
import { useGlobalStore } from 'store';

import GenerateVideo from './LayoutGenerateDialog.video';
import GenerateAudio from './LayoutGenerateDialog.audio';
import GenerateImage from './LayoutGenerateDialog.image';

export interface LayoutGenerateDialogProps {}

const defaultProps: Partial<LayoutGenerateDialogProps> = {};

type FormType = 'image' | 'audio' | 'video';

const formComponents = {
  image: GenerateImage,
  audio: GenerateAudio,
  video: GenerateVideo,
};

const LayoutGenerateDialog: React.FC<LayoutGenerateDialogProps> = () => {
  const [type, setType] = useState<FormType>('image');
  const { isGenerateOpen, setGenerateOpen, setGenerateProps } =
    useGlobalStore();

  const FormComponent = formComponents[type];

  return (
    <Dialog
      open={isGenerateOpen}
      onOpenChange={value => {
        if (!value) {
          setGenerateProps(null);
        }
        setGenerateOpen(value);
      }}
    >
      <DialogContent className={'max-w-3xl'}>
        <DialogHeader>
          <DialogTitle>Imagine</DialogTitle>
        </DialogHeader>
        <FormComponent
          {...{
            prompt,
            adornment: <Adornment {...{ type, setType }} />,
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

LayoutGenerateDialog.defaultProps = defaultProps;

export default LayoutGenerateDialog;

type ActionSlotProps = {
  type: FormType;
  setType: (type: FormType) => void;
};

const Adornment = ({ type, setType }: ActionSlotProps) => {
  return (
    <div className={'flex flex-nowrap gap-1'}>
      {[
        {
          key: 'image',
          label: 'Image',
          IconComponent: Image,
        },
        {
          key: 'audio',
          label: 'Audio',
          IconComponent: AudioLines,
        },
        {
          key: 'video',
          label: 'Video',
          IconComponent: Video,
        },
      ].map(({ key, label, IconComponent }) => {
        return (
          <Badge
            key={key}
            variant={key === type ? 'default' : 'outline'}
            className={'rounded-full font-normal cursor-pointer'}
            onClick={() => setType(key as FormType)}
          >
            <IconComponent
              className={'h-3 w-3'}
              style={{
                marginRight: type === key ? '0.25rem' : 0,
              }}
            />
            {type === key && label}
          </Badge>
        );
      })}
    </div>
  );
};
