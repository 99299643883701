import { ProductType } from '@/utils/api/stripe';
import { differenceInHours } from 'date-fns';
import {
  addDoc,
  collection,
  doc,
  onSnapshot,
  or,
  query,
  where,
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import _ from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import {
  useCollection,
  useCollectionData,
  useDocumentData,
} from 'react-firebase-hooks/firestore';
import { toast } from 'sonner';

import { TODO } from 'store/global.types';
import { db, functions } from 'utils/api/firebase';
import { stripeClient } from 'utils/api/stripe';
import { TOAST_DEFAULTS } from 'utils/config';

import getNextConfig from 'next/config';
import { ROUTES } from '@/utils/routes';

const env = getNextConfig().publicRuntimeConfig;

const IS_DEBUG = process.env.IS_DEBUG && true;

type Props = {
  wsId: string;
};

const tiers = [
  // legacy
  ProductType.Basic,
  ProductType.Standard,
  ProductType.Pro,
  ProductType.Studio,
  // new
  ProductType.EKEssential,
  ProductType.EKPro,
  ProductType.EKStudio,
  ProductType.EKEnterprise,
];

const useSubscriptions = ({ wsId }: Props) => {
  const [loading, setLoading] = useState(false);

  const [productsData] = useCollection(
    query(collection(db, 'products'), where('active', '==', true))
  );

  const [subscriptions, subscriptionsLoading] = useCollectionData(
    query(
      collection(db, 'customers', wsId, 'subscriptions'),
      or(where('status', '==', 'active'))
    ),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
      initialValue: [],
    }
  );

  const [payments] = useCollectionData(
    query(
      collection(db, 'customers', wsId, 'payments'),
      where('status', '==', 'succeeded')
    ),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
      initialValue: [],
    }
  );

  const weeklyPassRoles = useMemo(() => {
    const roles = payments.flatMap(payment => {
      const created = new Date(payment.created * 1000);
      const hoursSincePayment = differenceInHours(new Date(), created);
      const items = payment?.items?.map(
        item =>
          item?.price?.metadata?.type === 'weeklyPass' &&
          item?.price?.metadata?.stripeRole
      );
      if (hoursSincePayment < 7 * 24) return items;
      return [];
    });
    return ['free', ...tiers.filter(role => roles.includes(role))];
  }, [payments]);

  const [cancelsData] = useCollectionData(
    query(
      collection(db, 'customers', wsId, 'subscriptions'),
      where('status', '!=', 'active')
    ),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
      initialValue: [],
    }
  );

  const [wsClaimsData] = useDocumentData(doc(db, 'claims', wsId), {
    snapshotListenOptions: { includeMetadataChanges: true },
    initialValue: {
      isInternal: false,
      stripeRole: 'free',
    },
  });

  const internalRole = wsClaimsData?.isInternal
    ? wsClaimsData?.stripeRole || 'basic'
    : 'free';

  const onManageSubscription = useCallback(async () => {
    setLoading(true);
    try {
      const functionRef = httpsCallable(
        functions,
        'ext-firestore-stripe-payments-createPortalLink'
      );
      const returnUrl = `${env.PUBLIC_URL}${ROUTES.ACCOUNT_BILLING.replace(
        '[wsId]',
        wsId
      )}`;
      const { data }: { data: TODO } = await functionRef({
        returnUrl,
      });
      if (!data?.url) return;
      window.location.assign(data.url);
    } catch (error) {
      if (IS_DEBUG)
        console.log(
          'useSubscriptions -- onManageSubscription -- error:',
          error
        );
    }
  }, [wsId]);

  const onWeeklyPass = useCallback(
    async (priceId: string) => {
      setLoading(true);
      try {
        const checkoutSessionsCollectionRef = collection(
          db,
          'customers',
          wsId,
          'checkout_sessions'
        );
        const url = `${env.PUBLIC_URL}${ROUTES.ACCOUNT_BILLING.replace(
          '[wsId]',
          wsId
        )}`;

        const docRef = await addDoc(checkoutSessionsCollectionRef, {
          price: priceId,
          mode: 'payment',
          automatic_tax: true,
          collect_shipping_address: false,
          allow_promotion_codes: true,
          success_url: url,
          cancel_url: url,
        });
        onSnapshot(docRef, async snap => {
          const data = snap.data();
          if (!data?.sessionId) return;
          const stripe = await stripeClient();
          stripe.redirectToCheckout({ sessionId: data?.sessionId });
        });
      } catch (error) {
        if (IS_DEBUG)
          console.log(
            'useSubscriptions -- onPassSubscription -- error:',
            error
          );
      }
    },
    [wsId]
  );

  const subscriptionRoles = useMemo(() => {
    const roles = subscriptions.map(sub => sub.role);
    return ['free', ...tiers.filter(role => roles.includes(role))];
  }, [subscriptions]);

  const wasCustomer = useMemo(() => {
    return !!cancelsData.length;
  }, [cancelsData]);

  const onSubscription = useCallback(
    async (priceId: string, quantity: number) => {
      setLoading(true);
      try {
        const checkoutSessionsCollectionRef = collection(
          db,
          'customers',
          wsId,
          'checkout_sessions'
        );
        const url = `${env.PUBLIC_URL}${ROUTES.ACCOUNT_BILLING.replace(
          '[wsId]',
          wsId
        )}`;
        const docRef = await addDoc(checkoutSessionsCollectionRef, {
          price: priceId,
          mode: 'subscription',
          automatic_tax: true,
          collect_shipping_address: false,
          allow_promotion_codes: true,
          success_url: url,
          cancel_url: url,
          quantity,
        });
        onSnapshot(docRef, async snap => {
          const data = snap.data();
          if (!data?.sessionId) return;
          const stripe = await stripeClient();
          stripe.redirectToCheckout({ sessionId: data?.sessionId });
        });
      } catch (error) {
        if (IS_DEBUG)
          console.log('useSubscriptions -- onSubscription -- error:', error);
        toast.error('Something went wrong. Please try again.', TOAST_DEFAULTS);
      }
    },
    [wsId]
  );

  const subscriptionRole = _.last(subscriptionRoles);

  const subscriptionPrice = useMemo(() => {
    const subscription = subscriptions.find(
      sub => sub.role === subscriptionRole
    );
    if (!subscription) return '';
    const [price] = subscription.prices;
    return price.path;
  }, [subscriptionRole, subscriptions]);

  return {
    loading,
    subscriptionsLoading,
    internalRole,
    weeklyPassRole: _.last(weeklyPassRoles),
    subscriptionRole,
    subscriptionQuantity: subscriptions.reduce(
      (acc, sub) => acc + sub.quantity,
      0
    ),
    subscriptionPrice,
    stripeRole: _.last([
      'free',
      ...tiers.filter(role =>
        [internalRole, ...weeklyPassRoles, ...subscriptionRoles].includes(role)
      ),
    ]),
    wasCustomer,
    productsData,
    onManageSubscription,
    onSubscription,
    onWeeklyPass,
  };
};

export default useSubscriptions;
