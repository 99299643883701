import * as Sentry from '@sentry/react';
import { useRouter } from 'next/router';
import { signOut } from 'next-auth/react';
import { useCallback } from 'react';

import { ROUTES } from 'utils/routes';
import { TODO } from '@/store/global.types';
import { IS_DEBUG } from '@/utils/api/common';

const useCommon = () => {
  const router = useRouter();
  // workspace id
  const wsId = router.query.wsId as string;
  // thread id
  const tId = router.query.tId as string;
  // document id
  const dId = router.query.dId as string;
  // imagination id
  const iId = router.query.iId as string;
  // voiceover id
  const vId = router.query.vId as string;
  // brand id
  const bId = router.query.bId as string;
  // project id
  const pId = router.query.pId as string;
  // collection id
  const cId = router.query.cId as string;

  const log = useCallback((...args: TODO[]) => {
    if (IS_DEBUG) console.log(...args);
  }, []);

  const onLogout = useCallback(
    async (route?: string) => {
      try {
        await signOut({
          callbackUrl: route || ROUTES.HOME,
        });
      } catch (error) {
        Sentry.captureException(error);
        log('useCommon -- onLogout -- error:', error);
      }
    },
    [log]
  );

  const routeTo = useCallback(
    async (props: { route: string; routeParams?: Record<string, string> }) => {
      const { route, routeParams = {} } = props;
      try {
        if (route.includes('[wsId]') && !wsId && !routeParams.wsId) {
          log('No workspace available for the route.');
          return;
        }

        let finalRoute = route;
        const paramMap = {
          wsId: routeParams.wsId || wsId, // Only wsId gets fallback
          tId: routeParams.tId,
          dId: routeParams.dId,
          iId: routeParams.iId,
          vId: routeParams.vId,
          bId: routeParams.bId,
          pId: routeParams.pId,
          cId: routeParams.cId,
        };

        // Replace all parameters in one loop
        Object.entries(paramMap).forEach(([key, value]) => {
          const placeholder = `[${key}]`;
          if (finalRoute.includes(placeholder) && value) {
            finalRoute = finalRoute.replace(placeholder, value);
          }
        });

        log('useCommon -- routeTo -- finalRoute:', finalRoute);

        router.push(finalRoute);
      } catch (error) {
        Sentry.captureException(error);
        log('useCommon -- routeTo -- error:', error);
      }
    },
    [log, router, wsId]
  );

  return {
    router,
    wsId,
    iId,
    bId,
    tId,
    pId,
    vId,
    cId,
    onLogout,
    routeTo,
    log,
  };
};

export default useCommon;
