import { Timestamp } from 'firebase/firestore';
import create from 'zustand';

import { TODO } from './global.types';
import { ProductType } from '@/utils/api/stripe';
import { toast } from 'sonner';
import { TOAST_DEFAULTS } from '@/utils/config';
import router from 'next/router';
import { ROUTES } from '@/utils/routes';

export interface GlobalStoreType {
  isWindowTooSmall: boolean;
  setWindowTooSmall: (state: boolean) => void;

  isCommandOpen: boolean;
  setCommandOpen: (state: boolean) => void;

  isNotificationsOpen: boolean;
  setNotificationsOpen: (state: boolean) => void;

  isGenerateOpen: boolean;
  setGenerateOpen: (state: boolean) => void;

  generateProps: TODO;
  setGenerateProps: (generateProps: TODO) => void;

  isDescribeOpen: boolean;
  setDescribeOpen: (state: boolean) => void;

  timestamp: Timestamp;
  setTimestamp: (timestamp: Timestamp) => void;

  isVoiceoverOpen: boolean;
  setVoiceoverOpen: (state: boolean) => void;

  voiceoverRef: TODO;
  setVoiceoverRef: (ref: TODO) => void;

  isCharacterOpen: boolean;
  setCharacterOpen: (state: boolean) => void;

  voiceRef: TODO;
  setVoiceRef: (ref: TODO) => void;

  voiceBrandRef: TODO;
  setVoiceBrandRef: (ref: TODO) => void;

  isCollectionOpen: boolean;
  setCollectionOpen: (state: boolean) => void;

  collectionRef: TODO;
  setCollectionRef: (ref: TODO) => void;

  isWelcomeOpen: boolean;
  setWelcomeOpen: (state: boolean) => void;

  isTrialOpen: boolean;
  setTrialOpen: (state: boolean) => void;

  isLightboxOpen: boolean;
  setLightboxOpen: (state: boolean) => void;

  lightboxIndex: number;
  setLightboxIndex: (index: number) => void;

  lightboxSlides: TODO;
  setLightboxSlides: (slides: TODO) => void;

  imaginationRef: TODO;
  setImaginationRef: (ref: TODO) => void;

  // stripe
  stripeRole: ProductType;
  setStripeRole: (role: ProductType) => void;

  wasCustomer: boolean;
  setWasCustomer: (wasCustomer: boolean) => void;

  checkStripeRole: () => boolean;
  showUpgradeToast: (props: {
    wsId: string;
    description: string;
    stripeRole: ProductType;
  }) => void;

  isSuperAdmin: boolean;
  setSuperAdmin: (isSuperAdmin: boolean) => void;

  isAdmin: boolean;
  setAdmin: (isAdmin: boolean) => void;

  isInternal: boolean;
  setInternal: (isInternal: boolean) => void;
}

const useGlobalStore = create<GlobalStoreType>(
  (
    set: (
      partial: (store: GlobalStoreType) => Partial<GlobalStoreType>
    ) => void,
    // eslint-disable-next-line
    get: () => GlobalStoreType
  ) => {
    return {
      isWindowTooSmall: false,
      setWindowTooSmall: state => {
        set(() => ({
          isWindowTooSmall: state,
        }));
      },

      isCommandOpen: false,
      setCommandOpen: state => {
        set(() => ({
          isCommandOpen: state,
        }));
      },

      isNotificationsOpen: false,
      setNotificationsOpen: state => {
        set(() => ({
          isNotificationsOpen: state,
        }));
      },

      isGenerateOpen: false,
      setGenerateOpen: state => {
        set(() => ({
          isGenerateOpen: state,
        }));
      },

      generateProps: null,
      setGenerateProps: generateProps => {
        set(() => ({
          generateProps,
        }));
      },

      isDescribeOpen: false,
      setDescribeOpen: state => {
        set(() => ({
          isDescribeOpen: state,
        }));
      },

      timestamp: Timestamp.now(),
      setTimestamp: timestamp => {
        set(() => ({
          timestamp,
        }));
      },

      isVoiceoverOpen: false,
      setVoiceoverOpen: state => {
        set(() => ({
          isVoiceoverOpen: state,
        }));
      },

      voiceoverRef: null,
      setVoiceoverRef: ref => {
        set(() => ({
          voiceoverRef: ref,
        }));
      },

      voiceBrandRef: null,
      setVoiceBrandRef: ref => {
        set(() => ({
          voiceBrandRef: ref,
        }));
      },

      isCharacterOpen: false,
      setCharacterOpen: state => {
        set(() => ({
          isCharacterOpen: state,
        }));
      },

      voiceRef: null,
      setVoiceRef: ref => {
        set(() => ({
          voiceRef: ref,
        }));
      },

      isCollectionOpen: false,
      setCollectionOpen: state => {
        set(() => ({
          isCollectionOpen: state,
        }));
      },

      collectionRef: null,
      setCollectionRef: ref => {
        set(() => ({
          collectionRef: ref,
        }));
      },

      isWelcomeOpen: false,
      setWelcomeOpen: state => {
        set(() => ({
          isWelcomeOpen: state,
        }));
      },

      isTrialOpen: false,
      setTrialOpen: state => {
        set(() => ({
          isTrialOpen: state,
        }));
      },

      isLightboxOpen: false,
      setLightboxOpen: state => {
        set(() => ({
          isLightboxOpen: state,
        }));
      },

      lightboxIndex: 0,
      setLightboxIndex: index => {
        set(() => ({
          lightboxIndex: index,
        }));
      },

      lightboxSlides: [],
      setLightboxSlides: slides => {
        set(() => ({
          lightboxSlides: slides,
        }));
      },

      imaginationRef: null,
      setImaginationRef: ref => {
        set(() => ({
          imaginationRef: ref,
        }));
      },

      stripeRole: ProductType.Free,
      setStripeRole: role => {
        set(() => ({
          stripeRole: role,
        }));
      },

      wasCustomer: false,
      setWasCustomer: wasCustomer => {
        set(() => ({
          wasCustomer,
        }));
      },

      checkStripeRole: () => {
        const { stripeRole, wasCustomer, setTrialOpen } = get();
        if (stripeRole === ProductType.Free && wasCustomer) {
          toast.error('Something went wrong.', {
            ...TOAST_DEFAULTS,
            description: 'Please check your billing status.',
            action: {
              label: 'Billing',
              onClick: () => {
                router.push(ROUTES.ACCOUNT_BILLING_FALLBACK);
              },
            },
          });
          return false;
        }
        if (stripeRole === ProductType.Free && !wasCustomer) {
          setTrialOpen(true);
          return false;
        }
        return true;
      },

      showUpgradeToast: props => {
        const { wsId, description, stripeRole } = props;
        toast.info(
          stripeRole === ProductType.Free
            ? 'Subscribe to unlock'
            : 'Update subscription',
          {
            ...TOAST_DEFAULTS,
            duration: 5000,
            description,
            action: {
              label: 'Billing',
              onClick: () => {
                router.push(ROUTES.ACCOUNT_BILLING.replace('[wsId]', wsId));
              },
            },
          }
        );
      },

      isSuperAdmin: false,
      setSuperAdmin: isSuperAdmin => {
        set(() => ({
          isSuperAdmin,
        }));
      },

      isAdmin: false,
      setAdmin: isAdmin => {
        set(() => ({
          isAdmin,
        }));
      },

      isInternal: false,
      setInternal: isInternal => {
        set(() => ({
          isInternal,
        }));
      },
    };
  }
);

export default useGlobalStore;
